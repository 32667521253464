import { type Configuration } from '../core/providers/ConfigurationContext'
import { type LapsApiReturnType, useLapsApi } from './useLapsApi'
import { type FileApiReturnType, useFileApi } from './useFileApi'
import {
  type TrackSessionApiReturnType,
  useTrackSessionApi,
} from './useTrackSessionApi'
import { type DriverApiReturnType, useDriverApi } from './useDriverApi'
import { type TrackApiReturnType, useTrackApi } from './useTrackApi'
import { type ConfigApiReturnType, useConfigApi } from './useConfigApi'
import { type VehicleApiReturnType, useVehicleApi } from './useVehicleApi'
import {
  type FeatureFlagsApiReturnType,
  useFeatureFlagsApi,
} from './useFeatureFlagApi'
import { type CustomError } from './useApiUtils'
import { useEffect, useState } from 'react'
import { type MapApiReturnType, useMapApi } from './useMapApi'
import { useError } from '../core/providers/ErrorProvider'

export interface ApiFunctions {
  lapsApi: LapsApiReturnType
  fileApi: FileApiReturnType
  trackSessionApi: TrackSessionApiReturnType
  driverApi: DriverApiReturnType
  trackApi: TrackApiReturnType
  configApi: ConfigApiReturnType
  featureFlagsApi: FeatureFlagsApiReturnType
  vehicleApi: VehicleApiReturnType
  mapApi: MapApiReturnType
  error: CustomError | null | undefined
}

const useApi = (bffConfig: Configuration | null): ApiFunctions => {
  const [error, setError] = useState<CustomError | null | undefined>(null)
  const { setError: setSharedError } = useError()
  const lapsApi = useLapsApi(bffConfig)
  const fileApi = useFileApi(bffConfig)
  const trackSessionApi = useTrackSessionApi(bffConfig)
  const driverApi = useDriverApi(bffConfig)
  const trackApi = useTrackApi(bffConfig)
  const mapApi = useMapApi(bffConfig)
  const featureFlagsApi = useFeatureFlagsApi(bffConfig)
  const configApi = useConfigApi()
  const vehicleApi = useVehicleApi(bffConfig)

  useEffect(() => {
    const errors = [
      lapsApi.error,
      fileApi.error,
      trackSessionApi.error,
      driverApi.error,
      trackApi.error,
      featureFlagsApi.error,
      vehicleApi.error,
      mapApi.error,
    ]
    const currentError = errors.find((e) => e !== null)
    setError(currentError)
    setSharedError(currentError?.message ?? null)
  }, [
    lapsApi.error,
    fileApi.error,
    trackSessionApi.error,
    driverApi.error,
    trackApi.error,
    featureFlagsApi.error,
    mapApi.error,
    vehicleApi.error,
  ])

  return {
    fileApi,
    trackSessionApi,
    configApi,
    lapsApi,
    driverApi,
    featureFlagsApi,
    trackApi,
    vehicleApi,
    mapApi,
    error,
  }
}

export default useApi
