// AuthInitializer.tsx
import type React from 'react'
import { useEffect, useState } from 'react'
import {
  type IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './msalConfig'
import { useConfiguration } from '../core/providers/ConfigurationContext'
import CenterSpinner from '../components/CenterSpinner'
// import useApi from './path-to-useApi';

interface AuthInitializerProps {
  children: React.ReactNode
}

const AuthInitializer: React.FC<AuthInitializerProps> = ({ children }) => {
  const bffConfig = useConfiguration()

  // const { getConfiguration } = useApi(null);
  const [msalInstance, setMsalInstance] =
    useState<IPublicClientApplication | null>(null)

  useEffect(() => {
    const initializeMsal = async () => {
      // const bffCOnfiguration = await getConfiguration();
      if (bffConfig?.clientId == null) {
        return
      }
      // const clientId = await getClientId();
      const config = {
        ...msalConfig,
        auth: {
          ...msalConfig.auth,
          clientId: bffConfig.clientId,
        },
      }
      const instance =
        await PublicClientApplication.createPublicClientApplication(config)
      setMsalInstance(instance)
    }

    void initializeMsal()
  }, [bffConfig])

  if (msalInstance == null) {
    return <CenterSpinner />
  }

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

export default AuthInitializer
