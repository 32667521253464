export interface PzRacingDataRow {
  time: number
  longitude: number
  latitude: number
  direction: number
  speed: number
  date: Date
}

export interface I2mDataRow {
  time: number
  longitude: number
  latitude: number
  direction: number
  speed: number
  date: Date
  altitude: number
}

export interface AimDataRow {
  time: number
  longitude: number
  latitude: number
  direction: number
  speed: number
  date: Date
  altitude: number
}

export interface CsvDataRow {
  cts: number
  longitude: number
  latitude: number
  heading: number
  speed: number
  date: Date
}

export interface Coordinate {
  longitude: number
  latitude: number
}

export interface UpsertLapRequest {
  lap: LapDto
}

export interface LapDto {
  id: string
  startTime: Date
  finishTime: Date
  lapTimeMs: number
  telemetry?: TelemetryDto
  trackSessionId: string
  weather: Weather
  trackId: string
  lapNumber: number
  driverName?: string
}

export enum WeatherCondition {
  Unknown,
  Dry,
  Wet,
  Damp,
}

export interface Weather {
  condition: WeatherCondition
  temperatureInCelsius?: number
}

export interface CreateTrackSessionRequest {
  name: string
  trackId: string
  laps: LapDto[]
  telemetry: TelemetryDto
  weather: Weather
}

export interface DriverDto {
  id: Guid
  name: string
}

export interface CreateVehicleRequest {
  name: string
  type: string
  brand: string
  isPrimary: boolean
  manufactureYear?: number
  model: string
  weightKg?: number
  engine: VehicleEngineDto
}
export interface VehicleDto {
  id: Guid
  name: string
  type: string
  brand: string
  manufactureYear: number
  model: string
  weightKg: number
  engine: VehicleEngineDto
}

export interface VehicleEngineDto {
  capacityCc?: number
  powerKw?: number
  torqueNm?: number
}

export enum TelemetryType {
  Gps = 'Gps',
  Gyroscope = 'Gyroscope',
  Accelerometer = 'Accelerometer',
}

export interface TrackSessionDto {
  id: Guid
  name: string
  trackId: Guid
  laps: Record<number, Guid>
  telemetry: TelemetryDto
  metadata: TrackSessionMetadataDto
}

export interface TrackSessionMetadataDto {
  fastestLapTime?: string
  fastLapNumber?: number
  totalSessionTime?: string
  totalSessionDistance?: number
  sessionStartTime?: Date
  weather?: Weather
}

export interface LatLngDto {
  latitude: number
  longitude: number
  altitude?: number | undefined
  speed: Speed
  time: DeviceTime
  distanceTravelled?: number | undefined
}

export interface Speed {
  kph: number
}

export interface Device {
  name: string
  version: string
}

export interface TelemetryDto {
  device: Device
  gps?: Gps
  gyroscope?: Gyroscope
  accelerometer?: Accelerometer
}

export interface Gps {
  positions: LatLngDto[]
}

export interface Gyroscope {
  unit: string
  direction: Direction
  gyroReading: GyroReading[]
}

export interface Accelerometer {
  unit: string
  direction: Direction
  accelerationReading: AccelerationReading[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Direction {}

export interface DeviceTime {
  synchronizationTime?: number
  recordedTime: Date
}

export interface GyroReading {
  time: DeviceTime
  x: number
  y: number
  z: number
}

export interface AccelerationReading {
  time: DeviceTime
  x: number
  y: number
  z: number
}

// export type TrackSessionDto = {
//     id?: string;
//     name: string;
//     track: Track;
// }

export interface Track {
  id: string
  name: string
  finishLine: Coordinate[]
  countryCode: string
  isUserSuggested: boolean
}

export interface UserSuggestedTrack {
  track: Track
  userId?: Guid
  status: UserSuggestedTrackStatus
}

export enum UserSuggestedTrackStatus {
  Unknown = 0,
  Pending,
  Rejected,
  Approved,
}

// public record UserSuggestedTrack(Guid Id, string Name, Coordinate[] FinishLine, Corner[] Corners, string CountryCode, Guid UserId, UserSuggestedTrackStatus Status);

export type Guid = string & { isGuid: true }
