import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Container from '@mui/material/Container'
import MenuIcon from '@mui/icons-material/Menu'
import { MainListItems } from '../../listiIems'
import type React from 'react'
import { type ReactNode, useEffect, useState } from 'react'
import { TestDataButton } from '../testing/TestDataButton'
import { TrackSessionProvider } from '../trackSession/TrackSessionContext'
import UserMenu from '../user/UserMenu'
import useApi from '../../api/useApi'
import { AppBar, Button, Drawer, Grid } from '@mui/material'
import { initializeAppInsights } from '../../monitoring/AppInsights'
import Copyright from '../../components/Copyright'
import {
  FileParsingProvider,
  useFileParsing,
} from '../../core/providers/FileParsingContext'
import Upload from '../upload/Upload'
import { useLocation } from 'react-router-dom'
import {
  LoadDrawerProvider,
  useLoadDrawer,
} from '../../core/providers/LoadDrawerContext'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { VehicleDrawerProvider } from '../../core/providers/VehicleDrawerContext'
import { useConfiguration } from '../../core/providers/ConfigurationContext'

interface DashboardProps {
  children: ReactNode
}

const DashboardContent: React.FC<DashboardProps> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const config = useConfiguration()
  const { driverApi } = useApi(config)
  const { setProgressPercent, setIsLoading, setApiError } = useFileParsing()
  const { toggleDrawer } = useLoadDrawer()

  const location = useLocation()
  useEffect(() => {
    const ensureDriverExist = async (): Promise<void> => {
      const response = await driverApi.getDriver()
      if (response == null) {
        return
      }
      const { appInsights } = initializeAppInsights(
        config.applicationInsightsConnectionString
      )
      const validatedId = response.id.replace(/[,;=| ]+/g, '_')
      appInsights.setAuthenticatedUserContext(validatedId)
    }

    void ensureDriverExist()
  }, [])
  const drawerWidth: number = 240
  const handleDrawerToggle = (): void => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Pitbrain
      </Typography>
      <Divider />
      <List component="nav">
        <MainListItems />
      </List>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={4} container alignItems="center">
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                Pitbrain
              </Typography>
              <img
                src={'./pitbrain_logo.png'}
                alt="Pitbrain logo"
                loading="lazy"
                width={50}
                height={50}
              />
            </Grid>
            <Grid
              item
              container
              xs={8}
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                {location.pathname === '/' && (
                  <Upload
                    setProgressPercent={setProgressPercent}
                    setIsLoading={setIsLoading}
                    setError={setApiError}
                  />
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  onClick={toggleDrawer}
                  sx={{
                    color: 'white',
                    display: { xs: 'none', sm: 'flex' },
                  }}
                  startIcon={<CloudDownloadIcon style={{ color: 'white' }} />}
                >
                  Load
                </Button>
              </Grid>
              {config?.environment !== 'Production' && (
                <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                  <TestDataButton />
                </Grid>
              )}
              <Grid item>
                <Badge color="secondary">
                  <UserMenu />
                </Badge>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container sx={{ mt: 1, mb: 4, maxWidth: '100%' }} maxWidth={false}>
          {children}
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  )
}

export const Dashboard: React.FC<DashboardProps> = ({ children }) => {
  return (
    <TrackSessionProvider>
      <FileParsingProvider>
        <VehicleDrawerProvider>
          <LoadDrawerProvider>
            <DashboardContent>{children}</DashboardContent>
          </LoadDrawerProvider>
        </VehicleDrawerProvider>
      </FileParsingProvider>
    </TrackSessionProvider>
  )
}
