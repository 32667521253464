import { useState, useCallback } from 'react'
import axios, { type AxiosError } from 'axios'
import { type CustomError, useApiUtils } from './useApiUtils'
import { type Configuration } from '../core/providers/ConfigurationContext'
import {
  type UserSuggestedTrack,
  type Coordinate,
  type LapDto,
  type Track,
} from '../apiTypes'
import { isEmptyGuid } from '../shared/utils/guid'

export interface TrackApiReturnType {
  getSharedLaps: (trackId: string) => Promise<LapDto[]>
  getTracks: (onlyTracksWithSharedLap: boolean) => Promise<Track[]>
  saveUserSuggestedTrack: (request: Track) => Promise<string | null>
  approveUserSuggestedTrack: (
    trackId: string,
    trackToUpdate: UserSuggestedTrack
  ) => Promise<string | null>
  getUserSuggestedTrack: () => Promise<UserSuggestedTrack[]>
  findTrack: (coordinate: Coordinate) => Promise<Track>
  getTrack: (trackId: string) => Promise<Track | null>
  error: CustomError | null
}

const trackUrl = `${process.env.REACT_APP_BACKEND_URL}/race-track`
const userSuggestedTrackUrl = `${trackUrl}/user-suggested`

export const useTrackApi = (
  bffConfig: Configuration | null
): TrackApiReturnType => {
  const [error, setError] = useState<CustomError | null>(null)
  const { getAuthToken, handleError, performApiCall } = useApiUtils(bffConfig)

  const resetErrors = () => {
    setError(null)
  }

  const getSharedLaps = useCallback(
    async (trackId: string): Promise<LapDto[]> => {
      if (!bffConfig) return []
      try {
        const token = await getAuthToken()
        const response = await axios.get(`${trackUrl}/${trackId}/laps/shared`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        return response.data as LapDto[]
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return []
      }
    },
    [bffConfig, getAuthToken]
  )

  const getTracks = useCallback(
    async (onlyTracksWithSharedLap: boolean): Promise<Track[]> => {
      if (!bffConfig) return []
      try {
        const token = await getAuthToken()
        const response = await axios.get(
          `${trackUrl}?onlyTracksWithSharedLap=${onlyTracksWithSharedLap}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data as Track[]
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return []
      }
    },
    [bffConfig, getAuthToken]
  )

  const findTrack = useCallback(
    async (coordinate: Coordinate): Promise<Track> => {
      const unknownTrack = {
        name: 'Unknown',
        finishLine: [],
        id: '00000000-0000-0000-0000-000000000000',
        countryCode: 'UN',
        isUserSuggested: false,
      }
      if (!bffConfig) return unknownTrack
      try {
        const token = await getAuthToken()
        const response = await axios.post(
          `${trackUrl}/find`,
          {
            latitude: coordinate.latitude,
            longitude: coordinate.longitude,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        if (response.data != null && response.data !== '') {
          return response.data as Track
        }
        const userSuggestedTrackResponse = await axios.post(
          `${userSuggestedTrackUrl}/find`,
          {
            latitude: coordinate.latitude,
            longitude: coordinate.longitude,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (
          userSuggestedTrackResponse.data != null &&
          userSuggestedTrackResponse.data !== ''
        ) {
          const userSuggestedTrack = userSuggestedTrackResponse.data as Track
          userSuggestedTrack.isUserSuggested = true
          return userSuggestedTrack
        }
        return unknownTrack
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return unknownTrack
      }
    },
    [bffConfig, getAuthToken]
  )

  const saveUserSuggestedTrack = useCallback(
    async (request: Track): Promise<string | null> => {
      if (!bffConfig) return null
      try {
        const token = await getAuthToken()
        const response = await axios.post(`${userSuggestedTrackUrl}`, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        return await Promise.resolve(response.data)
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  const approveUserSuggestedTrack = useCallback(
    async (
      trackId: string,
      trackToUpdate: UserSuggestedTrack
    ): Promise<string | null> => {
      if (!bffConfig) return null
      try {
        const token = await getAuthToken()
        const response = await axios.put(
          `${userSuggestedTrackUrl}/${trackId}`,
          trackToUpdate,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.status === 200 ? response.data : null
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  const getUserSuggestedTracks = useCallback(async (): Promise<
    UserSuggestedTrack[]
  > => {
    if (!bffConfig) return []
    try {
      const token = await getAuthToken()
      const response = await axios.get(`${userSuggestedTrackUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      return response.data as UserSuggestedTrack[]
    } catch (error) {
      const handledError = handleError(error as AxiosError)
      setError(handledError)
      return []
    }
  }, [bffConfig, getAuthToken])

  const getTrack = useCallback(
    async (trackId: string): Promise<Track | null> => {
      if (!bffConfig) return null

      const unknownTrack = {
        name: 'Unknown',
        finishLine: [],
        id: '00000000-0000-0000-0000-000000000000',
        countryCode: 'UN',
        isUserSuggested: false,
      }

      if (isEmptyGuid(trackId)) {
        return unknownTrack
      }

      try {
        const token = await getAuthToken()

        try {
          const response = await axios.get(`${trackUrl}/${trackId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })

          if (response.data != null && response.data !== '') {
            return response.data as Track
          }

          return unknownTrack
        } catch (error) {
          const userSuggestedTrackResponse = await axios.get(
            `${userSuggestedTrackUrl}/${trackId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          )

          if (
            userSuggestedTrackResponse.data != null &&
            userSuggestedTrackResponse.data !== ''
          ) {
            const userSuggestedTrack = userSuggestedTrackResponse.data as Track
            userSuggestedTrack.isUserSuggested = true
            return userSuggestedTrack
          }
          return unknownTrack
        }
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  return {
    getSharedLaps: performApiCall(getSharedLaps, resetErrors),
    saveUserSuggestedTrack: performApiCall(saveUserSuggestedTrack, resetErrors),
    approveUserSuggestedTrack: performApiCall(
      approveUserSuggestedTrack,
      resetErrors
    ),
    getUserSuggestedTrack: performApiCall(getUserSuggestedTracks, resetErrors),
    getTracks: performApiCall(getTracks, resetErrors),
    findTrack: performApiCall(findTrack, resetErrors),
    getTrack: performApiCall(getTrack, resetErrors),
    error,
  }
}
