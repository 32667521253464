import CircularProgress, {
  type CircularProgressProps,
} from '@mui/material/CircularProgress'
import { CircularProgressWithLabel } from './CircularProgressWithLabel'

interface ProgressIndicatorProps extends CircularProgressProps {
  progress: number
  isLoading: boolean
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  progress,
  isLoading,
  ...props
}) => {
  if (isLoading) {
    return (
      <>
        {progress > 0 && progress < 100 ? (
          <CircularProgressWithLabel value={progress} {...props} />
        ) : (
          <CircularProgress {...props} />
        )}
      </>
    )
  }
  return <></>
}

export default ProgressIndicator
