import lapsData from '../../laps.json'
import { Lap } from '../../models/Lap'
import { v4 as uuidv4 } from 'uuid'
import { ExtendedLatLong } from '../../models/ExtendedLatLong'
import { TrackSession } from '../../models/TrackSession'
import { useTrackSessionContext } from '../trackSession/TrackSessionContext'
import { Button } from '@mui/material'
import { LatLng } from 'leaflet'
import { calculateDistance } from '../timeDrift/useTimeDrift'

export const TestDataButton = () => {
  const { setTrackSessions } = useTrackSessionContext()
  const onButtonClick = () => {
    const laps = lapsData.map((lapData, i) => {
      const lapId = uuidv4()
      let totalDistance = 0
      const readings = lapData.latLng.map((latLng, index, array) => {
        if (index > 0) {
          const prev = array[index - 1]
          totalDistance += calculateDistance(
            new LatLng(prev.lat, prev.lng),
            new LatLng(latLng.lat, latLng.lng)
          )
        }
        return new ExtendedLatLong(
          latLng.lat,
          latLng.lng,
          new Date(latLng.date),
          latLng.speed,
          totalDistance,
          latLng.timeString,
          undefined
        )
      })
      const tmpLap = new Lap(
        lapId,
        { latLngs: readings, device: { name: 'PzRacing', version: '1.0' } },
        new Date(lapData.startTime),
        new Date(lapData.finishTime),
        '0',
        '',
        i,
        'Test rider',
        '00000000-0000-0000-0000-000000000000',
        readings[0].distanceTravelled,
        readings.at(-1)?.distanceTravelled ?? 0
      )
      return tmpLap
    })
    laps[0].isSelected = true
    setTrackSessions([
      new TrackSession(
        { device: { name: 'PzRacing', version: '1.0' } },
        laps,
        { hasLeanAngle: false, hasVideo: false },
        '',
        {
          name: 'Unknown',
          finishLine: [],
          id: '00000000-0000-0000-0000-000000000000',
          countryCode: 'UN',
          isUserSuggested: false,
        },
        'Test File'
      ),
    ])
  }

  return (
    <Button
      variant="contained"
      size="small"
      onClick={onButtonClick}
      color="secondary"
    >
      Use test data
    </Button>
  )
}
