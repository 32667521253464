import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import BarChartIcon from '@mui/icons-material/BarChart'
import BrushIcon from '@mui/icons-material/Brush'
import PeopleIcon from '@mui/icons-material/People'
import { ListItemButton } from '@mui/material'
import { Fragment } from 'react'
import { useFeatureFlags } from './core/providers/FeatureFlagContext'
import Tooltip from '@mui/material/Tooltip'
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler'

export const MainListItems: React.FC = () => {
  const featureFlags = useFeatureFlags()

  return (
    <Fragment>
      <ListItemButton href="/">
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Analyse session" />
      </ListItemButton>
      <ListItemButton href="/compare-laps">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Compare with others" />
      </ListItemButton>
      {featureFlags.vehicle ? (
        <Tooltip title="Vehicles">
          <ListItemButton href="/vehicles">
            <ListItemIcon>
              <TwoWheelerIcon />
            </ListItemIcon>
            <ListItemText primary="See your vehicles" />
          </ListItemButton>
        </Tooltip>
      ) : (
        <Tooltip title="Feature not yet available">
          <span>
            <ListItemButton disabled>
              <ListItemIcon>
                <TwoWheelerIcon />
              </ListItemIcon>
              <ListItemText primary="See your vehicles" />
            </ListItemButton>
          </span>
        </Tooltip>
      )}
      {featureFlags.drawOnTrack ? (
        <ListItemButton href="/draw-on-track">
          <ListItemIcon>
            <BrushIcon />
          </ListItemIcon>
          <ListItemText primary="Draw on track" />
        </ListItemButton>
      ) : (
        <Tooltip title="Feature not yet available">
          <span>
            <ListItemButton disabled>
              <ListItemIcon>
                <BrushIcon />
              </ListItemIcon>
              <ListItemText primary="Draw on track" />
            </ListItemButton>
          </span>
        </Tooltip>
      )}
    </Fragment>
  )
}
