import type React from 'react'
import { useEffect, useRef } from 'react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { msalConfig, createLoginRequest } from '../auth/msalConfig'
import { useConfiguration } from '../core/providers/ConfigurationContext'
import LandingPage from './LandingPage'

interface Props {
  children: React.ReactNode
}

export const LoginPage: React.FC<Props> = ({ children }) => {
  const config = useConfiguration()
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const loginRequest = createLoginRequest(config?.apiScope)
  const checkSSOCount = useRef(0)
  useEffect(() => {
    const checkSSO = async (): Promise<void> => {
      checkSSOCount.current += 1
      if (inProgress !== InteractionStatus.None || checkSSOCount.current > 1) {
        return
      }
      try {
        const ssoResult = await instance.ssoSilent(loginRequest)
        if (ssoResult.accessToken != null && ssoResult.accessToken !== '') {
          await instance
            .loginRedirect({
              ...msalConfig.auth,
              ...loginRequest,
              scopes: loginRequest.scopes ?? [],
            })
            .catch((e) => {
              console.error(e)
            })
        }
      } catch (error) {
        console.log('Error acquiring token:', error)
      }
    }

    if (!isAuthenticated) void checkSSO()
  }, [isAuthenticated, inProgress])

  const handleLogin = async (): Promise<void> => {
    await instance
      .loginRedirect({
        ...msalConfig.auth,
        ...loginRequest,
        scopes: loginRequest.scopes ?? [],
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <>
      {children}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <LandingPage onLogin={handleLogin} />
    </>
  )
}
