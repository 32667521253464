import {
  BrowserCacheLocation,
  type SsoSilentRequest,
} from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    authority: `https://pitbrain.b2clogin.com/pitbrain.onmicrosoft.com/B2C_1_signupsignin`,
    knownAuthorities: ['pitbrain.b2clogin.com'],
    redirectUri: window.location.origin + '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
}

export const protectedResources = {
  bff: {
    scopes: {
      read: [
        'https://pitbrain.onmicrosoft.com/583592b5-0b6d-fabd-c876-5633803a1731/read',
      ],
    },
  },
}

export const createProtectedResources = (
  apiScope: string
): typeof protectedResources => ({
  bff: {
    scopes: {
      read: [apiScope],
    },
  },
})

export const createLoginRequest = (
  apiScope: string | undefined
): SsoSilentRequest => {
  if (!apiScope) {
    return {
      scopes: [],
    }
  }

  return {
    scopes: [...createProtectedResources(apiScope).bff.scopes.read],
  }
}
