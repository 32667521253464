import React from 'react'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { useConfiguration } from './providers/ConfigurationContext'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { initializeAppInsights } from '../monitoring/AppInsights'
import { FeatureFlagsProvider } from './providers/FeatureFlagContext'
import { LoginPage } from '../pages/LoginPage'
import AppRouter from './AppRouter'
const App: React.FC = () => {
  const config = useConfiguration()
  const { reactPlugin } = initializeAppInsights(
    config.applicationInsightsConnectionString
  )
  return (
    <div className="App">
      <header className="App-header"></header>
      <React.Fragment>
        <AuthenticatedTemplate>
          <FeatureFlagsProvider>
            <AppInsightsContext.Provider value={reactPlugin}>
              <AppRouter />
            </AppInsightsContext.Provider>
          </FeatureFlagsProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage>
            <></>
          </LoginPage>
        </UnauthenticatedTemplate>
      </React.Fragment>
    </div>
  )
}

export default App
