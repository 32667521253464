import { useState, useCallback } from 'react'
import axios, { type AxiosError } from 'axios'
import { type CustomError, useApiUtils } from './useApiUtils'
import { type Configuration } from '../core/providers/ConfigurationContext'
import {
  type I2mDataRow,
  type CsvDataRow,
  type PzRacingDataRow,
  type AimDataRow,
} from '../apiTypes'
import { aggregateData } from '../shared/utils/data-processing/average-functions'

// Extend your hook's return type to include error handling
export interface FileApiReturnType {
  convertSesFile: (file: File) => Promise<PzRacingDataRow[]>
  convertDatFile: (file: File) => Promise<I2mDataRow[]>
  convertXrkFile: (file: File) => Promise<AimDataRow[]>
  convertCsvFile: (file: File) => Promise<CsvDataRow[]>
  error: CustomError | null
}

export const useFileApi = (
  bffConfig: Configuration | null
): FileApiReturnType => {
  const [error, setError] = useState<CustomError | null>(null)

  const resetErrors = () => {
    setError(null)
  }

  const { getAuthToken, handleError, readFileAsBase64, performApiCall } =
    useApiUtils(bffConfig)

  const convertSesFile = useCallback(
    async (file: File): Promise<PzRacingDataRow[]> => {
      if (!bffConfig) return []
      try {
        const token = await getAuthToken()
        const base64 = await readFileAsBase64(file)
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/file/pzracing/convert`,
          { file: base64 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return aggregateData(response.data as PzRacingDataRow[])
      } catch (e) {
        const handledError = handleError(e as AxiosError)
        setError(handledError)
        return []
      }
    },
    [bffConfig, getAuthToken]
  )

  const convertDatFile = useCallback(
    async (file: File): Promise<I2mDataRow[]> => {
      if (!bffConfig) return []
      try {
        const token = await getAuthToken()
        const base64 = await readFileAsBase64(file)
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/file/i2m/convert`,
          { file: base64 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data
      } catch (e) {
        const handledError = handleError(e as AxiosError)
        setError(handledError)
        return []
      }
    },
    [bffConfig, getAuthToken]
  )

  const convertXrkFile = useCallback(
    async (file: File): Promise<I2mDataRow[]> => {
      if (!bffConfig) return []
      try {
        const token = await getAuthToken()
        const base64 = await readFileAsBase64(file)
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/file/aim/convert`,
          { file: base64 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data
      } catch (e) {
        const handledError = handleError(e as AxiosError)
        setError(handledError)
        return []
      }
    },
    [bffConfig, getAuthToken]
  )

  const convertCsvFile = useCallback(
    async (file: File): Promise<CsvDataRow[]> => {
      if (!bffConfig) return []
      try {
        const token = await getAuthToken()
        const base64 = await readFileAsBase64(file)
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/file/csv/convert`,
          { file: base64 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data
      } catch (e) {
        console.error('Error converting CSV file:', e)
        const handledError = handleError(e as AxiosError)
        setError(handledError)
        return []
      }
    },
    [bffConfig, getAuthToken]
  )

  return {
    convertSesFile: performApiCall(convertSesFile, resetErrors),
    convertDatFile: performApiCall(convertDatFile, resetErrors),
    convertXrkFile: performApiCall(convertXrkFile, resetErrors),
    convertCsvFile: performApiCall(convertCsvFile, resetErrors),
    error,
  }
}
