import { ExtendedLatLong } from '../../models/ExtendedLatLong'
import { type Telemetry } from '../../api/Telemetry.dto'
import { TelemetryType, type I2mDataRow } from '../../apiTypes'
import useApi from '../../api/useApi'
import ConfigurationContext from '../../core/providers/ConfigurationContext'
import { useContext } from 'react'
import { useBaseProcessor } from './useBaseProcessor'
import { LatLng } from 'leaflet'
import { calculateDistance } from '../timeDrift/useTimeDrift'

export const useI2mProcesser = () => {
  const bffConfig = useContext(ConfigurationContext)
  const api = useApi(bffConfig)
  const { loadTrack, getFirstCoordinate, updateTrackSessions } =
    useBaseProcessor()
  const { error: apiError } = api

  const parseTelemetry = async (results: I2mDataRow[]): Promise<Telemetry> => {
    let totalDistance = 0
    const latLngs = results.map((p: I2mDataRow, index, array) => {
      if (index > 0) {
        const prev = array[index - 1]
        totalDistance += calculateDistance(
          new LatLng(prev.latitude, prev.longitude),
          new LatLng(p.latitude, p.longitude)
        )
      }
      const speed = p.speed

      const latlng: ExtendedLatLong = new ExtendedLatLong(
        p.latitude,
        p.longitude,
        new Date(p.date),
        speed,
        totalDistance,
        undefined,
        p.time
      )
      latlng.alt = p.altitude
      return latlng
    })
    return { latLngs, device: { name: 'I2M', version: '1.0' } }
  }

  const processFile = async (file: File, sessionId: string): Promise<void> => {
    const storedTrackSession = await api.trackSessionApi.getTrackSessionByName(
      file.name,
      [TelemetryType.Gps]
    )
    if (storedTrackSession) {
      const sessionFeatures = { hasVideo: false, hasLeanAngle: false }
      await loadTrack(storedTrackSession, sessionFeatures)
      return
    }
    const results = await api.fileApi.convertDatFile(file)
    const telemetry = await parseTelemetry(results)
    const firstCoordinate = getFirstCoordinate(telemetry)
    if (firstCoordinate == null) return
    const track = await api.trackApi.findTrack(firstCoordinate)
    updateTrackSessions(
      telemetry,
      sessionId,
      track,
      { hasLeanAngle: false, hasVideo: false },
      file.name
    )
  }

  return { processFile, error: apiError }
}
