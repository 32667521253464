import axios, { type AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import { type Configuration } from '../core/providers/ConfigurationContext'
import { type CustomError, useApiUtils } from './useApiUtils'

export interface MapApiReturnType {
  getTile: (
    x: number,
    y: number,
    z: number,
    s: string
  ) => Promise<Blob | undefined>
  error: CustomError | null
}

const mapUrl = `${process.env.REACT_APP_BACKEND_URL}/maps`

export const useMapApi = (
  bffConfig: Configuration | null
): MapApiReturnType => {
  const [error, setError] = useState<CustomError | null>(null)
  const { getAuthToken, handleError, performApiCall } = useApiUtils(bffConfig)

  const resetErrors = () => {
    setError(null)
  }

  const getTile = useCallback(
    async (
      x: number,
      y: number,
      z: number,
      s: string
    ): Promise<Blob | undefined> => {
      if (!bffConfig) return undefined

      try {
        const token = await getAuthToken()
        const response = await axios.get<Blob>(
          `${mapUrl}/tiles?x=${x}&y=${y}&z=${z}&s=${s}`,
          {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return undefined
      }
    },
    [bffConfig, getAuthToken]
  )

  return {
    getTile: performApiCall(getTile, resetErrors),
    error,
  }
}
