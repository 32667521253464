import { type AxiosError } from 'axios'
import { useMsal } from '@azure/msal-react'
import { type Configuration } from '../core/providers/ConfigurationContext'
import { AuthError } from '@azure/msal-browser'

export interface CustomError {
  error: boolean
  message: string
  code?: number
}

export const useApiUtils = (bffConfig: Configuration | null) => {
  const { instance, accounts } = useMsal()

  const getAuthToken = async () => {
    if (!bffConfig) {
      throw new Error('BFF Configuration is not available')
    }

    try {
      const token = await instance.acquireTokenSilent({
        scopes: [bffConfig.apiScope], // protectedResources.bff.scopes.read,
        account: accounts[0],
      })

      if (!token.accessToken || token.accessToken === '') {
        await instance.acquireTokenRedirect({ scopes: [bffConfig.apiScope] }) // {scopes: protectedResources.bff.scopes.read})
      } else {
        return token.accessToken
      }
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: [bffConfig.apiScope],
        account: accounts[0],
      })

      //   if (!tokenResponse.accessToken || tokenResponse.accessToken === "") {
      //     await instance.acquireTokenRedirect({scopes: [bffConfig!.apiScope]})//{scopes: protectedResources.bff.scopes.read})
      //  }

      return tokenResponse.accessToken
    } catch (error) {
      if (
        error instanceof AuthError &&
        (error.errorCode === 'interaction_required' ||
          error.errorMessage.includes('AADB2C90077'))
      ) {
        await instance.acquireTokenRedirect({ scopes: [bffConfig.apiScope] })
      } else {
        console.error('Error acquiring token:', error)
        throw error
      }
    }
  }

  interface ProblemDetail {
    type?: string
    title?: string
    status?: number
    detail: string
    instance?: string
  }
  type ApiCallFunction<P extends unknown[], R> = (...args: P) => Promise<R>

  function isApiErrorResponse(data: unknown): data is ProblemDetail {
    return (
      typeof data === 'object' &&
      data !== null &&
      'detail' in data &&
      typeof (data as { detail: unknown }).detail === 'string'
    )
  }

  const handleError = (error: AxiosError): CustomError | null => {
    console.error('API call failed: ', error)
    if (error.response?.status === 404) {
      return null
    }
    let errorMessage = 'An unexpected error occurred'
    if (error.response && isApiErrorResponse(error.response.data)) {
      errorMessage = error.response.data.detail
    } else if (error.message) {
      errorMessage = error.message
    }

    const statusCode = error.response?.status
    return { error: true, message: errorMessage, code: statusCode }
  }

  const performApiCall = <P extends unknown[], R>(
    apiCall: ApiCallFunction<P, R>,
    resetApiErrors: () => void
  ): ApiCallFunction<P, R> => {
    return async (...args: P): Promise<R> => {
      resetApiErrors()
      return await apiCall(...args)
    }
  }

  const readFileAsBase64 = async (file: File): Promise<string> => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        resolve(reader.result as string)
      }

      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  return { getAuthToken, handleError, readFileAsBase64, performApiCall }
}
