import React, { useContext, useEffect, useState } from 'react'
import useApi from '../../api/useApi'
import { useConfiguration } from './ConfigurationContext'
import CenterSpinner from '../../components/CenterSpinner'

export interface FeatureFlags {
  drawOnTrack: boolean
  leanAngle: boolean
  comments: boolean
  vehicle: boolean
}

const defaultFeatureFlags: FeatureFlags = {
  drawOnTrack: false,
  leanAngle: false,
  comments: false,
  vehicle: false,
}

const FeatureFlagContext = React.createContext<FeatureFlags | null>(null)

const FeatureFlagProvider = FeatureFlagContext.Provider
export const FeatureFlagConsumer = FeatureFlagContext.Consumer
export default FeatureFlagContext

interface Props {
  children: React.ReactNode
}

export const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagContext)
  if (context == null) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider')
  }
  return context
}

export const FeatureFlagsProvider: React.FC<Props> = ({ children }) => {
  const bffConfig = useConfiguration()
  const { featureFlagsApi } = useApi(bffConfig)
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null)

  function isFeatureFlags(data: unknown): data is FeatureFlags {
    if (typeof data !== 'object' || data === null) {
      return false
    }

    const obj: Record<string, unknown> = data as Record<string, unknown>
    const requiredProps: Array<[key: string, type: string]> = [
      ['drawOnTrack', 'boolean'],
      ['leanAngle', 'boolean'],
      ['comments', 'boolean'],
      ['compare', 'boolean'],
    ]

    // Check for the existence and type of each required property.
    for (const [prop, type] of requiredProps) {
      if (!(prop in obj) || typeof obj[prop] !== type) { // eslint-disable-line
        return false
      }
    }

    return true
  }

  useEffect(() => {
    async function initializeFeatureFlags(): Promise<void> {
      try {
        const response = await featureFlagsApi.getFeatureFlags()
        if (response != null && isFeatureFlags(response)) {
          setFeatureFlags(response)
        } else {
          setFeatureFlags(defaultFeatureFlags)
          console.error('Unable to set feature flags.')
        }
      } catch (error) {
        setFeatureFlags(defaultFeatureFlags)
        console.error('Unable to fetch feature flags.', error)
      }
    }

    void initializeFeatureFlags()
  }, [])

  if (featureFlags == null) {
    return <CenterSpinner width="100%" />
  }

  return (
    <FeatureFlagProvider value={featureFlags}>{children}</FeatureFlagProvider>
  )
}
