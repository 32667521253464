import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { truncateString } from '../../shared/utils/string'
import VehicleForm from '../vehicles/VehicleForm'
import { GenericDrawer } from '../../components/GenericDrawer'
import { useVehicleDrawer } from '../../core/providers/VehicleDrawerContext'
import { useFeatureFlags } from '../../core/providers/FeatureFlagContext'
const UserMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isDrawerOpen, toggleDrawer } = useVehicleDrawer()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const featureFlags = useFeatureFlags()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { accounts } = useMsal()

  const account = accounts[0]?.idTokenClaims

  const name = account?.name ?? ''

  // SignOutButton Component returns a button that invokes a redirect logout when clicked

  return (
    <div>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
      >
        <PersonIcon />
        {name !== '' && !isMobile && (
          <Typography
            variant="subtitle1"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {truncateString(name, 20)}
          </Typography>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock={true}
      >
        {featureFlags.vehicle && (
          <MenuItem onClick={toggleDrawer}>Vehicle</MenuItem>
        )}

        <SignOutButton />
      </Menu>
      {featureFlags.vehicle && (
        <GenericDrawer
          isOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          drawerSide="right"
          content={<VehicleForm onCancel={() => {}} />}
        />
      )}
    </div>
  )
}

const SignOutButton: React.FC = () => {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance, accounts } = useMsal()

  const signOutClickHandler = async () => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: window.location.origin,
    }
    await instance.logoutRedirect(logoutRequest)
  }

  return <MenuItem onClick={signOutClickHandler}>Sign out</MenuItem>
}

export default UserMenu
