import { type Telemetry } from '../../api/Telemetry.dto'
import {
  type Coordinate,
  type Track,
  type TrackSessionDto,
} from '../../apiTypes'
import useApi from '../../api/useApi'
import { type SessionFeatures, TrackSession } from '../../models/TrackSession'
import { Lap } from '../../models/Lap'
import ConfigurationContext from '../../core/providers/ConfigurationContext'
import { useContext } from 'react'
import { useTrackSessionContext } from '../trackSession/TrackSessionContext'

export const useBaseProcessor = () => {
  const { setTrackSessions, trackSessions } = useTrackSessionContext()

  const bffConfig = useContext(ConfigurationContext)
  const api = useApi(bffConfig)
  const loadTrack = async (
    trackSession: TrackSessionDto,
    sessionFeatures: SessionFeatures
  ): Promise<void> => {
    const track = await api.trackApi.getTrack(trackSession.trackId)
    if (track == null) {
      return
    }

    const sharedLaps = await api.trackSessionApi.getSharedLapsForTrackSession(
      trackSession.id
    )
    const laps: Lap[] =
      sharedLaps?.map((l) =>
        Lap.fromDto(
          l,
          { latLngs: [], device: { name: '', version: '' } },
          track.id,
          trackSession.name,
          trackSession.id
        )
      ) ?? []
    laps.forEach((l) => (l.isShared = true))
    const ts = TrackSession.fromDto(trackSession, track, laps, sessionFeatures)
    if (
      trackSessions?.length &&
      trackSessions[0].track?.id !== trackSession.trackId
    ) {
      throw Error(
        'The uploaded file is from a different track than the session that is already loaded.'
      )
    }
    setTrackSessions((prevState) => [...prevState, ts])
  }

  const getFirstCoordinate = (telemetry: Telemetry): Coordinate | null => {
    if (telemetry.latLngs?.[0] == null || telemetry.latLngs?.[0] == null) {
      return null
    }
    return {
      latitude: telemetry.latLngs[0].lat,
      longitude: telemetry.latLngs[0].lng,
    }
  }

  const updateTrackSessions = (
    telemetry: Telemetry,
    sessionId: string,
    track: Track,
    options: SessionFeatures,
    fileName: string
  ): void => {
    if (trackSessions?.length && trackSessions[0].track?.id !== track.id) {
      throw Error(
        'The uploaded file is from a different track than the session that is already loaded.'
      )
    }

    setTrackSessions((prevState) => [
      ...prevState,
      new TrackSession(telemetry, [], options, sessionId, track, fileName),
    ])
  }

  return { loadTrack, getFirstCoordinate, updateTrackSessions }
}
