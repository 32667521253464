// ProgressDisplay.tsx
import type React from 'react'
import { Grid } from '@mui/material'
import ProgressIndicator from './ProgressIndicator'

interface ProgressDisplayProps {
  isLoading: boolean
  progress: number
}

const ProgressDisplay: React.FC<ProgressDisplayProps> = ({
  isLoading,
  progress,
}) => {
  if (!isLoading) {
    return null
  }

  return (
    <Grid
      container
      style={{ height: '100vh' }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <ProgressIndicator progress={progress} isLoading={isLoading} />
      </Grid>
    </Grid>
  )
}

export default ProgressDisplay
