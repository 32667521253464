import { useState, useCallback } from 'react'
import axios, { type AxiosError } from 'axios'
import { type CustomError, useApiUtils } from './useApiUtils'
import { type Configuration } from '../core/providers/ConfigurationContext'
import { type DriverDto } from '../apiTypes'

export interface DriverApiReturnType {
  getDriver: () => Promise<DriverDto | null>
  error: CustomError | null
}

export const useDriverApi = (
  bffConfig: Configuration | null
): DriverApiReturnType => {
  const [error, setError] = useState<CustomError | null>(null)
  const { getAuthToken, handleError, performApiCall } = useApiUtils(bffConfig)
  const resetErrors = () => {
    setError(null)
  }
  const getDriver = useCallback(async (): Promise<DriverDto | null> => {
    if (!bffConfig) return null
    try {
      const token = await getAuthToken()
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/driver`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      return response.data as DriverDto
    } catch (error) {
      const handledError = handleError(error as AxiosError)
      setError(handledError)
      return null
    }
  }, [bffConfig, getAuthToken])

  return { getDriver: performApiCall(getDriver, resetErrors), error }
}
