import type React from 'react'
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  CssBaseline,
  Container,
  useTheme,
  Grid,
  Card,
  CardContent,
} from '@mui/material'
import { Copyright } from '@mui/icons-material'
import ShowcaseComponent from '../features/landing/ShowcaseComponent'

interface LandingPageProps {
  onLogin: () => Promise<void>
}

const LandingPage: React.FC<LandingPageProps> = ({ onLogin }) => {
  const theme = useTheme() // Use the theme hook for accessing the theme in your component

  return (
    <>
      <CssBaseline />
      <AppBar position="static" sx={{ width: '100%' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" noWrap component="div">
              Pitbrain
            </Typography>
            <img
              src="./pitbrain_logo.png"
              alt="Pitbrain logo"
              loading="lazy"
              style={{ marginLeft: 8, width: 50, height: 50 }}
            />
          </Box>
          <Button color="inherit" onClick={onLogin}>
            Login
          </Button>
        </Toolbar>
      </AppBar>

      <ShowcaseComponent />

      <Container maxWidth="sm" sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Share laps and get faster together
        </Typography>
        <Typography variant="body1" paragraph>
          Pitbrain makes getting faster on tracks for motorcycles and cars
          straightforward, whether you&apos;re on your phone, iPad, or computer.
          We help you understand exactly how to improve, by analyzing your
          sessions and comparing laps with others. Embrace smarter racing with
          less guesswork and experience the difference in your performance.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onLogin}
          sx={{ mt: 2 }}
        >
          Login to start
        </Button>
      </Container>

      <Box sx={{ bgcolor: theme.palette.grey[100], py: 4 }}>
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Supported Devices
          </Typography>
          <Typography variant="body1" paragraph>
            We currently support the following devices, with plans to expand.
            Your feedback is crucial—let us know your preferred devices!
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {['GoPro (6 to 11)', 'PzRacing', 'QStarz', 'I2M'].map((device) => (
              <Grid item xs={12} sm={4} key={device}>
                <Card>
                  <CardContent>
                    <Typography variant="body1">{device}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: 4 }}>
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <Copyright />
        </Container>
      </Box>
    </>
  )
}

export default LandingPage
