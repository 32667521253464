import { useState, useCallback } from 'react'
import axios, { type AxiosError } from 'axios'
import { type CustomError, useApiUtils } from './useApiUtils'
import { type Configuration } from '../core/providers/ConfigurationContext'
import {
  type TelemetryType,
  type CreateTrackSessionRequest,
  type LapDto,
  type TrackSessionDto,
} from '../apiTypes'

// Extend your hook's return type to include error handling
export interface TrackSessionApiReturnType {
  saveTrackSession: (
    request: CreateTrackSessionRequest
  ) => Promise<string | null>
  getSharedLapsForTrackSession: (
    tracksession: string
  ) => Promise<LapDto[] | null>
  getAllTrackSessions: () => Promise<TrackSessionDto[]>
  getTrackSessionByName: (
    trackSessionName: string,
    telemetryTypes: TelemetryType[]
  ) => Promise<TrackSessionDto | null>
  getTrackSession: (
    trackSessionId: string,
    telemetryTypes: TelemetryType[]
  ) => Promise<TrackSessionDto | null>
  error: CustomError | null
}

export const useTrackSessionApi = (
  bffConfig: Configuration | null
): TrackSessionApiReturnType => {
  const [error, setError] = useState<CustomError | null>(null)
  const { getAuthToken, handleError, performApiCall } = useApiUtils(bffConfig)
  const resetErrors = () => {
    setError(null)
  }
  const saveTrackSession = useCallback(
    async (request: CreateTrackSessionRequest): Promise<string | null> => {
      if (!bffConfig) return null
      try {
        const token = await getAuthToken()
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/tracksession`,
          request,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return await Promise.resolve(response.data)
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  const getSharedLapsForTrackSession = useCallback(
    async (tracksession: string): Promise<LapDto[] | null> => {
      // const unknownTrack = { name: 'Unknown', finishLine: [], id: "00000000-0000-0000-0000-000000000000" };
      if (!bffConfig) return null
      try {
        const token = await getAuthToken()
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/tracksession/${tracksession}/laps/shared`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )

        return response.data as LapDto[]
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  const getAllTrackSessions = useCallback(async (): Promise<
    TrackSessionDto[]
  > => {
    if (!bffConfig) return []
    try {
      const token = await getAuthToken()
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/tracksession`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )

      return response.data as TrackSessionDto[]
    } catch (error) {
      const handledError = handleError(error as AxiosError)
      setError(handledError)
      return []
    }
  }, [bffConfig, getAuthToken])

  const getTrackSessionByName = useCallback(
    async (
      trackSessionName: string,
      telemetryTypes: TelemetryType[] = []
    ): Promise<TrackSessionDto | null> => {
      if (!bffConfig) return null
      try {
        const token = await getAuthToken()
        const telemetryQuery = telemetryTypes
          .map((t) => `telemetryTypes=${t}`)
          .join('&')
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/tracksession?name=${trackSessionName}${telemetryTypes.length ? '&' : ''}${telemetryQuery}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )

        return response.data as TrackSessionDto
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  const getTrackSession = useCallback(
    async (
      trackSessionId: string,
      telemetryTypes: TelemetryType[] = []
    ): Promise<TrackSessionDto | null> => {
      if (!bffConfig) return null
      try {
        const token = await getAuthToken()
        const telemetryQuery = telemetryTypes
          .map((t) => `telemetryTypes=${t}`)
          .join('&')
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/tracksession/${trackSessionId}?${telemetryQuery}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )

        return response.data as TrackSessionDto
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  return {
    saveTrackSession: performApiCall(saveTrackSession, resetErrors),
    getSharedLapsForTrackSession: performApiCall(
      getSharedLapsForTrackSession,
      resetErrors
    ),
    getAllTrackSessions: performApiCall(getAllTrackSessions, resetErrors),
    getTrackSessionByName: performApiCall(getTrackSessionByName, resetErrors),
    getTrackSession: performApiCall(getTrackSession, resetErrors),
    error,
  }
}
