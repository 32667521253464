import { ExtendedLatLong } from '../../models/ExtendedLatLong'
import { type Telemetry } from '../../api/Telemetry.dto'
import { TelemetryType, type CsvDataRow } from '../../apiTypes'
import useApi from '../../api/useApi'
import ConfigurationContext from '../../core/providers/ConfigurationContext'
import { useContext } from 'react'
import { useBaseProcessor } from './useBaseProcessor'
import { LatLng } from 'leaflet'
import { calculateDistance } from '../timeDrift/useTimeDrift'

export const useCsvProcessor = () => {
  const bffConfig = useContext(ConfigurationContext)
  const api = useApi(bffConfig)
  const { loadTrack, getFirstCoordinate, updateTrackSessions } =
    useBaseProcessor()
  const { error: apiError } = api

  const parseCsv = async (results: CsvDataRow[]): Promise<Telemetry> => {
    let totalDistance = 0
    const latLngs = results.map((p: CsvDataRow, index, array) => {
      if (index > 0) {
        const prev = array[index - 1]
        totalDistance += calculateDistance(
          new LatLng(prev.latitude, prev.longitude),
          new LatLng(p.latitude, p.longitude)
        )
      }
      const speed = p.speed
      return new ExtendedLatLong(
        p.latitude,
        p.longitude,
        new Date(p.date),
        speed,
        totalDistance,
        undefined,
        p.cts
      )
    })
    return { latLngs, device: { name: 'PzRacing', version: '1.0' } }
  }

  const processCsvFile = async (file: File, sessionId: string) => {
    const storedTrackSession = await api.trackSessionApi.getTrackSessionByName(
      file.name,
      [TelemetryType.Gps]
    )
    if (storedTrackSession) {
      const sessionFeatures = { hasVideo: false, hasLeanAngle: false }
      await loadTrack(storedTrackSession, sessionFeatures)
      await Promise.resolve()
      return
    }
    const results = await api.fileApi.convertCsvFile(file)
    if (results.length === 0) {
      await Promise.resolve()
      return
    }
    const telemetry = await parseCsv(results)
    const firstCoordinate = getFirstCoordinate(telemetry)
    if (firstCoordinate == null) return
    const track = await api.trackApi.findTrack(firstCoordinate)
    updateTrackSessions(
      telemetry,
      sessionId,
      track,
      { hasLeanAngle: false, hasVideo: false },
      file.name
    )
    await Promise.resolve()
  }

  return { processCsvFile, error: apiError }
}
