import type React from 'react'
import { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ProgressDisplay from '../components/ProgressDisplay'
import { Dashboard } from '../features/dashboard/Dashboard'
const TrackSessionAnalysisDashboard = lazy(
  async () => await import('../pages/TrackSessionAnalysisDashboard')
)
const DrawPage = lazy(async () => await import('../pages/DrawPage'))
const VehiclesPage = lazy(async () => await import('../pages/VehiclesPage'))
const UserSuggestedTracksPage = lazy(
  async () => await import('../pages/UserSuggestedTracksPage')
)
const CompareLapsDashboard = lazy(
  async () => await import('../pages/CompareLapsDashboard')
)

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<ProgressDisplay isLoading={true} progress={0} />}>
      <Routes>
        <Route
          path="/"
          element={
            <Dashboard>
              <TrackSessionAnalysisDashboard />
            </Dashboard>
          }
        />
        <Route
          path="/draw-on-track"
          element={
            <Dashboard>
              <DrawPage />
            </Dashboard>
          }
        />
        <Route
          path="/compare-laps"
          element={
            <Dashboard>
              <CompareLapsDashboard />
            </Dashboard>
          }
        />
        <Route
          path="/vehicles"
          element={
            <Dashboard>
              <VehiclesPage />
            </Dashboard>
          }
        />
        <Route
          path="/suggested-tracks"
          element={
            <Dashboard>
              <UserSuggestedTracksPage />
            </Dashboard>
          }
        />
      </Routes>
    </Suspense>
  </BrowserRouter>
)

export default AppRouter
