import {
  type TrackSessionMetadataDto,
  type Track,
  type TrackSessionDto,
  type Guid,
} from '../apiTypes'
import { Lap } from './Lap'
import { LatLng } from 'leaflet'
import { v4 as uuidv4 } from 'uuid'
import { lineIntersect, lineString } from '@turf/turf'
import {
  AverageAccelerometerEveryTenthSecond,
  AverageGyroEveryTenthSecond,
} from '../shared/utils/data-processing/average-functions'
import { mapToTelemetry } from '../shared/utils/mappers/telemetryMapper'
import { isEmptyGuid } from '../shared/utils/guid'
import { type Telemetry } from '../api/Telemetry.dto'

export interface SessionFeatures {
  hasVideo: boolean
  hasLeanAngle: boolean
}

export class TrackSession {
  laps: Lap[]
  telemetry: Telemetry
  sessionFeatures: SessionFeatures
  id: string
  track: Track
  fileName: string
  isStored: boolean = false
  lapsIdentifiers?: Record<number, Guid>
  metaData?: TrackSessionMetadataDto

  constructor(
    telemetry: Telemetry,
    laps: Lap[],
    sessionFeatures: SessionFeatures,
    sessionId: string,
    track: Track,
    fileName: string
  ) {
    this.laps = laps
    this.telemetry = telemetry
    this.sessionFeatures = sessionFeatures
    this.id = sessionId
    this.track = track
    this.fileName = fileName
  }

  hasKnownTrack = () => {
    return this.track != null && !isEmptyGuid(this.track.id)
  }

  toggleSelectedLap = (i: number) => {
    if (!this.laps?.length) return

    if (
      !!this.laps[i].isSelected &&
      this.laps.filter((lap) => lap.isSelected).length <= 1
    )
      return

    const tempLaps = this.laps
    tempLaps[i].isSelected = !tempLaps[i].isSelected

    this.laps = [...tempLaps]
  }

  applyStartFinishForSession = (sessionName: number, finishLine: LatLng[]) => {
    let internalFinishLine = finishLine
    if (this.track.name !== 'Unknown') {
      const track = this.track
      internalFinishLine = [
        new LatLng(track.finishLine[0].latitude, track.finishLine[0].longitude),
        new LatLng(track.finishLine[1].latitude, track.finishLine[1].longitude),
      ]
    }

    const latLngs = this.telemetry.latLngs
    if (
      this?.telemetry?.latLngs == null ||
      internalFinishLine.length !== 2 ||
      latLngs == null
    ) {
      throw new Error('Invalid telemetry or finish line')
    }

    const intersections = []
    for (let i = 0; i < latLngs.length - 1; i++) {
      const intersection = lineIntersect(
        lineString([
          [latLngs[i].lat, latLngs[i].lng],
          [latLngs[i + 1].lat, latLngs[i + 1].lng],
        ]),
        lineString([
          [internalFinishLine[0].lat, internalFinishLine[0].lng],
          [internalFinishLine[1].lat, internalFinishLine[1].lng],
        ])
      )
      if (intersection.features.length > 0) {
        intersections.push(i)
      }
    }
    if (intersections.length === 0) {
      // TODO: Shouldn't there be two intersections for a lap?
      // throw new Error("You need to set a new finish line");
      // setErrorMessage("You need to set a new finish line");
      return
    }

    const laps: Lap[] = []
    for (let i = 0; i < intersections.length - 1; i++) {
      const readings = latLngs.slice(intersections[i], intersections[i + 1]) // Should add +1 as intersections[i] contains point on previous lap.
      const lapStartTime = readings[0].date
      const lapFinishTime = readings.at(-1)?.date ?? lapStartTime
      // const acclInLap = accl.samples.filter(x => lapStartTime < new Date(x.date) && new Date(x.date) < lapFinishTime)
      // let leanAngles = getLeanAngles(gyroInLap, acclInLap)

      let lapId = uuidv4()
      if (this.lapsIdentifiers?.[i] != null) {
        lapId = this.lapsIdentifiers[i]
      }
      const lap = new Lap(
        lapId,
        { latLngs: readings, device: this.telemetry.device },
        lapStartTime,
        lapFinishTime,
        sessionName.toString(),
        this.id,
        0,
        'Unknown',
        this.track.id,
        readings[0].distanceTravelled,
        readings.at(-1)?.distanceTravelled ?? readings[0].distanceTravelled
      )
      if (this.telemetry.gyro) {
        lap.gyro = this.telemetry.gyro.filter(
          (x) => lapStartTime < x.date && x.date < lapFinishTime
        )
        lap.telemetry.gyro = lap.gyro
        lap.gyro = AverageGyroEveryTenthSecond(lap.gyro)
      }
      if (this.telemetry.accl) {
        lap.accl = this.telemetry.accl.filter(
          (x) => lapStartTime < x.date && x.date < lapFinishTime
        )
        lap.telemetry.accl = lap.accl
        lap.accl = AverageAccelerometerEveryTenthSecond(lap.accl)
        // lap.simpleMovingAverage(lap.gyroReading.map(x => x.leanAngle))
      }
      // lap.gyroReading = gyroInLap.map(x => ({cts: x.cts, date: new Date(x.date), leanAngle: Math.atan(x["Gyroscope (y) [rad/s]"] / Math.sqrt(Math.pow(x["Gyroscope (x) [rad/s]"], 2.0) + Math.pow(x["Gyroscope (z) [rad/s]"], 2.0)))}))
      // lap.gyroReading = gyroInLap.map(x => new GyroReading(x.cts, x["Gyroscope (x) [rad/s]"], x["Gyroscope (y) [rad/s]"], x["Gyroscope (z) [rad/s]"], x.date, lapStartTime,))
      // const newLean = lap.simpleMovingAverage(lap.gyroReading.map(x => x.leanAngle))
      // const newLean = lap.simpleMovingAverage(leanAngles)
      // for (let i = 0; i < newLean.length; i++) {
      //   lap.gyroReading[i].leanAngle = newLean[i]
      // }
      lap.findBreakingAndSlowestPoints(3, 15)
      laps.push(lap)
    }
    if (laps.length === 0) {
      // TODO: User error message. Inform that no full lap could be calculated.
      // throw new Error('No laps could be calculated.')
      // setErrorMessage("No laps could be calculated.")
      return
    }

    const sharedLaps = this.laps.filter((lap) => lap.isShared)
    for (let i = 0; i < laps.length; i++) {
      laps[i].lapNumber = i
      if (sharedLaps.some((l) => l.lapNumber === i)) {
        laps[i].isShared = true
      }
    }

    laps[0].isSelected = true
    this.laps = [...laps]
  }

  setLaps = (lapIds: Record<number, Guid>) => {
    const laps = this.laps
    for (let i = 0; i < laps.length; i++) {
      const lapId = lapIds[i]
      laps[i].id = lapId
    }
    this.laps = [...laps]
  }

  static fromDto = (
    trackSessionDto: TrackSessionDto,
    track: Track,
    laps: Lap[],
    sessionFeatures: SessionFeatures
  ): TrackSession => {
    const telemetry = mapToTelemetry(trackSessionDto.telemetry)
    const ts = new TrackSession(
      telemetry,
      laps,
      sessionFeatures,
      trackSessionDto.id,
      track,
      trackSessionDto.name
    )
    ts.isStored = true
    ts.lapsIdentifiers = trackSessionDto.laps
    ts.metaData = trackSessionDto.metadata
    return ts
  }
}
