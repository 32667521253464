import { type GyroSample, type AcclSample } from '../api/Telemetry.dto'
import { type ExtendedLatLong } from './ExtendedLatLong'

export class LapElapsedTimeDistance {
  lapId: string
  latLng: ExtendedLatLong
  startTime: Date
  finishTime: Date
  lapTime: number
  lapNumber: number
  timeString: string
  gyro?: GyroSample
  accl?: AcclSample
  elapsedMs: number
  constructor(
    lapId: string,
    latLng: ExtendedLatLong,
    startTime: Date,
    finishTime: Date,
    lapNumber: number,
    timeString: string
  ) {
    this.lapId = lapId
    this.latLng = latLng
    this.startTime = startTime
    this.finishTime = finishTime
    this.lapTime = finishTime.getTime() - startTime.getTime()
    this.lapNumber = lapNumber
    this.timeString = timeString
    this.elapsedMs = latLng.date.getTime() - startTime.getTime()
  }
}
