import {
  type TelemetryDto,
  type Gps,
  type Gyroscope,
  type Accelerometer,
  type GyroReading,
  type AccelerationReading,
  type DeviceTime,
} from '../../../apiTypes'
import { ExtendedLatLong } from '../../../models/ExtendedLatLong'
import { LatLng } from 'leaflet'
import {
  type Telemetry,
  type GyroReading2,
  type AcclReading,
} from '../../../api/Telemetry.dto'
import { calculateDistance } from '../../../features/timeDrift/useTimeDrift'

export const mapToTelemetryDto = (source: Telemetry): TelemetryDto => {
  const telemetryDto: TelemetryDto = {
    device: { name: source.device.name, version: source.device.version },
  }

  // Map GPS readings
  if (source.latLngs != null && source.latLngs.length > 0) {
    telemetryDto.gps = {
      positions: source.latLngs.map((latLng) => ({
        latitude: latLng.lat,
        longitude: latLng.lng,
        altitude: latLng.alt,
        speed: { kph: latLng.speed ?? 0 },
        time: {
          synchronizationTime: latLng.cts,
          recordedTime: latLng.date,
        },
        distanceTravelled: latLng.distanceTravelled,
      })),
    }
  }

  // Map Gyroscope readings
  if (source.gyro != null && source.gyro.length > 0) {
    const gyroReadings = source.gyro
    telemetryDto.gyroscope = {
      unit: gyroReadings[0].unit,
      direction: {},
      gyroReading: gyroReadings.map((gyro) => ({
        time: {
          synchronizationTime: gyro.cts,
          recordedTime: gyro.date,
        },
        x: gyro.x,
        y: gyro.y,
        z: gyro.z,
      })),
    }
  }

  // Map Accelerometer readings
  if (source.accl != null && source.accl.length > 0) {
    const acclReadings = source.accl
    telemetryDto.accelerometer = {
      unit: acclReadings[0].unit,
      direction: {},
      accelerationReading: acclReadings.map((accl) => ({
        time: {
          synchronizationTime: accl.cts,
          recordedTime: accl.date,
        },
        x: accl.x,
        y: accl.y,
        z: accl.z,
      })),
    }
  }
  return telemetryDto
}

export const mapToTelemetry = (source: TelemetryDto): Telemetry => {
  const telemetry: Telemetry = {
    device: { name: source.device.name, version: source.device.version },
  }

  // Map GPS readings
  if (source.gps != null && source.gps.positions.length > 0) {
    let totalDistance = 0
    telemetry.latLngs = source.gps.positions.map((latLngDto, index, array) => {
      if (index > 0 && latLngDto.distanceTravelled == null) {
        const prev = array[index - 1]
        totalDistance += calculateDistance(
          new LatLng(prev.latitude, prev.longitude),
          new LatLng(latLngDto.latitude, latLngDto.longitude)
        )
      }

      return new ExtendedLatLong(
        latLngDto.latitude,
        latLngDto.longitude,
        new Date(latLngDto.time.recordedTime),
        latLngDto.speed.kph,
        latLngDto.distanceTravelled ?? totalDistance,
        undefined,
        latLngDto.time.synchronizationTime
      )
    })
  }

  // Map Gyroscope readings
  if (
    source.gyroscope?.gyroReading != null &&
    source.gyroscope.gyroReading.length > 0
  ) {
    telemetry.gyro = source.gyroscope.gyroReading.map((gyro) => ({
      cts: gyro.time.synchronizationTime ?? 0,
      date: new Date(gyro.time.recordedTime),
      x: gyro.x,
      y: gyro.y,
      z: gyro.z,
      unit: source.gyroscope?.unit ?? '',
    }))
  }

  // Map Accelerometer readings
  if (
    source.accelerometer?.accelerationReading &&
    source.accelerometer.accelerationReading.length > 0
  ) {
    telemetry.accl = source.accelerometer.accelerationReading.map((accl) => ({
      cts: accl.time.synchronizationTime ?? 0,
      date: new Date(accl.time.recordedTime),
      x: accl.x,
      y: accl.y,
      z: accl.z,
      unit: source.accelerometer?.unit ?? '',
    }))
  }

  return telemetry
}

export const mapFromTelemetry = (source: Telemetry): TelemetryDto => {
  const telemetryDto: TelemetryDto = {
    device: { name: source.device.name, version: source.device.version },
    gps: mapGpsData(source.latLngs),
    gyroscope: mapGyroData(source.gyro),
    accelerometer: mapAcclData(source.accl),
  }

  return telemetryDto
}

const mapGpsData = (latLngs?: ExtendedLatLong[]): Gps | undefined => {
  if (!latLngs) {
    return undefined
  }

  return {
    positions: latLngs.map((ll) => ({
      latitude: ll.lat,
      longitude: ll.lng,
      speed: { kph: ll.speed },
      time: convertDeviceTime(ll.date),
      distanceTravelled: ll.distanceTravelled,
    })),
  }
}

const mapGyroData = (gyroReadings?: GyroReading2[]): Gyroscope | undefined => {
  if (!gyroReadings) {
    return undefined
  }

  return {
    unit: gyroReadings[0].unit, // Assuming all readings have the same unit
    direction: {}, // Direction is not provided in Telemetry
    gyroReading: gyroReadings.map((gr) => convertGyroReading(gr)),
  }
}

const mapAcclData = (
  acclReadings?: AcclReading[]
): Accelerometer | undefined => {
  if (!acclReadings) {
    return undefined
  }

  return {
    unit: acclReadings[0].unit, // Assuming all readings have the same unit
    direction: {}, // Direction is not provided in Telemetry
    accelerationReading: acclReadings.map((ar) => convertAcclReading(ar)),
  }
}

const convertDeviceTime = (time: Date): DeviceTime => {
  return {
    recordedTime: time,
    synchronizationTime: undefined, // Original Telemetry does not provide this
  }
}

const convertGyroReading = (reading: GyroReading2): GyroReading => {
  return {
    time: convertDeviceTime(reading.date),
    x: reading.x,
    y: reading.y,
    z: reading.z,
  }
}

const convertAcclReading = (reading: AcclReading): AccelerationReading => {
  return {
    time: convertDeviceTime(reading.date),
    x: reading.x,
    y: reading.y,
    z: reading.z,
  }
}
