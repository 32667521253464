import type React from 'react'
import {
  createContext,
  useContext,
  useState,
  type ReactNode,
  useEffect,
  useMemo,
} from 'react'
import { type TrackSession } from '../../models/TrackSession' // Import your TrackSession class
import { type LatLng } from 'leaflet'
import { type VideoSession } from '../video/VideoSession'

interface TrackSessionContextProps {
  trackSessions: TrackSession[]
  setTrackSessions: React.Dispatch<React.SetStateAction<TrackSession[]>>
  // toggleSelectedLap: (sessionId: string, i: number) => void
  canApplyStartFinish: (finishLine: LatLng[]) => boolean
  applyStartFinishForAllSessions: (finishLine?: LatLng[]) => void
  error: string | null
  resetError: () => void
  videoSessions: VideoSession[]
  setVideoSessions: React.Dispatch<React.SetStateAction<VideoSession[]>>
}

const TrackSessionContext = createContext<TrackSessionContextProps | undefined>(
  undefined
)

export const useTrackSessionContext = (): TrackSessionContextProps => {
  const context = useContext(TrackSessionContext)
  if (!context) {
    throw new Error(
      'useTrackSession must be used within a TrackSessionProvider'
    )
  }
  return context
}

interface TrackSessionProviderProps {
  children: ReactNode
}

export const TrackSessionProvider: React.FC<TrackSessionProviderProps> = ({
  children,
}) => {
  const [trackSessions, setTrackSessions] = useState<TrackSession[]>([])
  const [finishLine, setFinishLine] = useState<LatLng[]>([])
  const [videoSessions, setVideoSessions] = useState<VideoSession[]>([])
  const [error, setError] = useState<string | null>(null)

  // const toggleSelectedLap = (sessionId: string, i: number) => {
  // }

  const resetError = () => {
    setError(null)
  }

  const hasValidFinishLine = (finishLine?: LatLng[]) => finishLine?.length === 2
  const hasTrackSessions = () => trackSessions.length > 0
  const hasLatLngs = () => trackSessions[0]?.telemetry?.latLngs != null
  const hasKnownTrack = () => trackSessions[0]?.track?.name !== 'Unknown'
  const canApplyStartFinish = (finishLine?: LatLng[]) =>
    hasTrackSessions() &&
    hasLatLngs() &&
    (hasValidFinishLine(finishLine) || hasKnownTrack())

  const applyStartFinishForAllSessions = (inputFinishLine?: LatLng[]) => {
    let internalFinishLine = []
    if (hasValidFinishLine(inputFinishLine) && inputFinishLine != null) {
      setFinishLine(inputFinishLine)
      internalFinishLine = inputFinishLine
    } else {
      internalFinishLine = finishLine
    }
    if (!canApplyStartFinish(internalFinishLine)) {
      return
    }
    for (let i = 0; i < trackSessions.length; i++) {
      try {
        trackSessions[i].applyStartFinishForSession(i, internalFinishLine)
      } catch (e) {
        setError(`Unable to process ${trackSessions[i].fileName}`)
        setVideoSessions([
          ...videoSessions.filter((v) => v.id !== trackSessions[i].id),
        ])
        trackSessions.splice(i, 1)
        setTrackSessions([...trackSessions])
        return
      }
    }
    setTrackSessions([...trackSessions])
  }

  useEffect(() => {
    try {
      applyStartFinishForAllSessions()
    } catch (e) {
      setError('Unable to process file.')
    }
  }, [trackSessions.length])

  const value = useMemo(
    () => ({
      trackSessions,
      setTrackSessions,
      // toggleSelectedLap,
      canApplyStartFinish,
      applyStartFinishForAllSessions,
      error,
      resetError,
      setVideoSessions,
      videoSessions,
    }),
    [
      trackSessions,
      setTrackSessions,
      // toggleSelectedLap,
      canApplyStartFinish,
      applyStartFinishForAllSessions,
      error,
      resetError,
      setVideoSessions,
      videoSessions,
    ]
  )

  return (
    <TrackSessionContext.Provider value={value}>
      {children}
    </TrackSessionContext.Provider>
  )
}
