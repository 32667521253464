import { useContext, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTrackSessionContext } from '../trackSession/TrackSessionContext'
import useApi from '../../api/useApi'
import ConfigurationContext from '../../core/providers/ConfigurationContext'
import { useCsvProcessor } from './useCsvProcessor'
import { usePzRacingProcesser } from './usePzRacingProcessor'
import { useGoProProcessor } from './useGoProProcessor'
import { useI2mProcesser } from './useI2mProcessor'
import { useAimProcesser } from './useAimProcessor'
export enum SupportedFileTypes {
  Ses = '.SES',
  Mp4 = '.MP4',
  Lrv = '.LRV',
  Csv = '.CSV',
  Dat = '.DAT',
  Xrk = '.XRK',
}

export interface UseFileParserProps {
  setProgressPercent: React.Dispatch<React.SetStateAction<number>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string | undefined | null>>
}

export const useFileParser = ({
  setProgressPercent,
  setIsLoading,
  setError,
}: UseFileParserProps) => {
  const bffConfig = useContext(ConfigurationContext)
  const { trackSessions } = useTrackSessionContext()
  const api = useApi(bffConfig)
  const { processCsvFile, error: csvError } = useCsvProcessor()
  const { processFile, error: pzRacingError } = usePzRacingProcesser()
  const { processFile: processI2mFile, error: i2mError } = useI2mProcesser()
  const { processFile: processAimFile, error: aimError } = useAimProcesser()
  const { processFile: processGoProFile, error: goProError } =
    useGoProProcessor(setProgressPercent)
  useEffect(() => {
    if (api?.error?.message) console.log('Error: ', api?.error?.message)

    setError(api?.error?.message)
  }, [api.error])

  useEffect(() => {
    if (csvError?.message) console.error('Error: ', csvError?.message)

    setError(csvError?.message)
  }, [csvError])

  useEffect(() => {
    if (pzRacingError?.message) console.error('Error: ', pzRacingError?.message)

    setError(pzRacingError?.message)
  }, [pzRacingError])

  useEffect(() => {
    if (i2mError?.message) console.error('Error: ', i2mError?.message)

    setError(i2mError?.message)
  }, [i2mError])

  useEffect(() => {
    if (aimError?.message) console.error('Error: ', aimError?.message)

    setError(aimError?.message)
  }, [aimError])

  useEffect(() => {
    if (goProError?.message) console.error('Error: ', goProError?.message)

    setError(goProError?.message)
  }, [goProError])

  const getFileType = (fileName: string): SupportedFileTypes | null => {
    if (fileName.toUpperCase().endsWith(SupportedFileTypes.Ses.toUpperCase())) {
      return SupportedFileTypes.Ses
    } else if (
      fileName.toUpperCase().endsWith(SupportedFileTypes.Lrv.toUpperCase())
    ) {
      return SupportedFileTypes.Lrv
    } else if (
      fileName.toUpperCase().endsWith(SupportedFileTypes.Mp4.toUpperCase())
    ) {
      return SupportedFileTypes.Mp4
    } else if (
      fileName.toUpperCase().endsWith(SupportedFileTypes.Csv.toUpperCase())
    ) {
      return SupportedFileTypes.Csv
    } else if (
      fileName.toUpperCase().endsWith(SupportedFileTypes.Dat.toUpperCase())
    ) {
      return SupportedFileTypes.Dat
    } else if (
      fileName.toUpperCase().endsWith(SupportedFileTypes.Xrk.toUpperCase())
    ) {
      return SupportedFileTypes.Xrk
    }
    return null
  }

  const parseFile = async (file: File) => {
    if (trackSessions.some((ts) => ts.fileName === file.name)) {
      // Might still be a scenario with duplicate track session id, but this is good enough for now.
      setError('This file is already loaded.')
      return
    }

    try {
      setIsLoading(true)
      setError(null)
      const fileType = getFileType(file.name)
      const sessionId = uuidv4()
      switch (fileType) {
        case SupportedFileTypes.Lrv:
        case SupportedFileTypes.Mp4:
          await processGoProFile(file, sessionId)
          return
        case SupportedFileTypes.Ses:
          await processFile(file, sessionId)
          return
        case SupportedFileTypes.Csv:
          await processCsvFile(file, sessionId)
          return
        case SupportedFileTypes.Dat:
          await processI2mFile(file, sessionId)
          return
        case SupportedFileTypes.Xrk:
          await processAimFile(file, sessionId)
          return
        default:
          throw new Error('Unsupported file type')
      }
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : String(error)
      console.log('An error occurred while processing the file.', errorMessage)
      setError(`An error occurred while processing the file. ${errorMessage}`)
    } finally {
      setIsLoading(false)
    }
  }

  return { parseFile }
}
