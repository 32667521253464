import { CircularProgress, Grid } from '@mui/material'
const CenterSpinner = ({ width = '300px', height = '100vh' }) => {
  return (
    <Grid
      container
      style={{ height, width }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

export default CenterSpinner
