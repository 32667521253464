import { type ExtendedLatLong } from './ExtendedLatLong'
import { type Weather, WeatherCondition, type LapDto } from '../apiTypes'
import {
  calculateMovingAverageSpeed,
  filterTroughsByLowestSpeedWithinWindow,
  findPeaks,
  identifyTroughsBetweenPeaks,
} from '../shared/utils/telemetryUtils'
import { LapElapsedTimeDistance } from './LapElapsedTimeDistance'
import { formatTimeSpan } from '../shared/utils/timeSpan'
import {
  type GyroReading2,
  type AcclReading,
  type Telemetry,
} from '../api/Telemetry.dto'

export class Lap {
  id: string
  private readonly latLng: ExtendedLatLong[]
  isSelected: boolean = false
  startTime: Date
  finishTime: Date
  lapTime: number
  lapNumber: number
  sessionName: string
  sessionId: string
  gyro?: GyroReading2[]
  accl?: AcclReading[]
  isShared: boolean = false
  telemetry: Telemetry
  driverName: string
  lapGroupingName: string
  trackId: string
  lapStartDistance: number
  lapFinishDistance: number
  breakingPoints: ExtendedLatLong[] = []
  slowestPoints: ExtendedLatLong[] = []
  topSpeed: number = 0
  lapElapsedTimeDistances: LapElapsedTimeDistance[]
  weather: Weather = { condition: WeatherCondition.Dry }

  constructor(
    id: string,
    telemetry: Telemetry,
    startTime: Date,
    finishTime: Date,
    sessionName: string,
    sessionId: string,
    lapNumber: number,
    driverName: string,
    trackId: string,
    lapStartDistance: number,
    lapFinishDistance: number
  ) {
    this.id = id
    this.telemetry = telemetry
    this.startTime = startTime
    this.finishTime = finishTime
    this.lapTime = finishTime.getTime() - startTime.getTime()
    this.sessionName = sessionName
    this.sessionId = sessionId
    this.lapNumber = lapNumber
    this.driverName = driverName
    this.lapGroupingName = this.sessionName
    this.trackId = trackId
    this.lapStartDistance = lapStartDistance
    this.lapFinishDistance = lapFinishDistance
    const lapElapsedTimeDistances =
      telemetry.latLngs?.map((x) => {
        const elapsedMs = x.date.getTime() - startTime.getTime()
        const timeString =
          formatTimeSpan(elapsedMs, {
            granularity: 'tenths',
            format: 'mm:ss.S',
          }) ?? ''
        x.timeString = timeString
        return new LapElapsedTimeDistance(
          id,
          x,
          startTime,
          finishTime,
          lapNumber,
          timeString
        )
      }) ?? []
    this.lapElapsedTimeDistances = lapElapsedTimeDistances
    this.latLng = lapElapsedTimeDistances.map((l) => l.latLng)
  }

  public getExtendedLatLongs(): ExtendedLatLong[] {
    return this.latLng
  }

  /**
   * Finds the `ExtendedLatLong` in the lap that matches or is closest to the given timeString.
   * @param targetTimeString The time string to match, in the format "mm:ss.S".
   * @returns The `ExtendedLatLong` object that is the closest match to the given timeString.
   */
  public findLatLngForTimeString(
    targetTimeString: string
  ): ExtendedLatLong | null {
    // Convert the target time string to milliseconds for comparison.
    const targetTimeMs = this.timeStringToMs(targetTimeString)

    // Initialize variables to keep track of the closest match.
    let closestLatLng: ExtendedLatLong | null = null
    let smallestTimeDiff = Infinity

    // Iterate through each LatLng to find the closest time match.
    for (const elapsed of this.lapElapsedTimeDistances) {
      const timeDiff = Math.abs(elapsed.elapsedMs - targetTimeMs)

      if (timeDiff < smallestTimeDiff) {
        smallestTimeDiff = timeDiff
        closestLatLng = elapsed.latLng
      }
    }

    return closestLatLng
  }

  /**
   * Converts a time string in the format "mm:ss.S" to milliseconds.
   * @param timeString The time string to convert.
   * @returns The time in milliseconds.
   */
  private timeStringToMs(timeString: string): number {
    const [minutes, seconds] = timeString.split(':')
    const [secs, tenths] = seconds.split('.')
    return (
      parseInt(minutes) * 60000 + parseInt(secs) * 1000 + parseInt(tenths) * 100
    )
  }

  public static msToTimeString(s: number) {
    return (
      (formatTimeSpan(s, {
        granularity: 'tenths',
        format: 'mm:ss.S',
      }) ?? '') + '00'
    )
  }

  findBreakingAndSlowestPoints(
    windowSize: number,
    thresholdPercentage: number
  ): void {
    const smoothedSpeeds = calculateMovingAverageSpeed(
      this.lapElapsedTimeDistances.map((y) => y.latLng),
      windowSize
    )
    this.topSpeed = Math.max(...smoothedSpeeds)
    const speedThreshold = this.topSpeed * (thresholdPercentage / 100)

    // Identify all potential peaks and troughs in the smoothed speed data.
    const peaks = findPeaks(smoothedSpeeds)
    let troughs = identifyTroughsBetweenPeaks(peaks, smoothedSpeeds)

    let isDeceleratingToEnd = true
    const lastComparisonPoint = Math.max(troughs.at(-1) ?? 0, peaks.at(-1) ?? 0)
    for (let i = lastComparisonPoint + 1; i < smoothedSpeeds.length - 1; i++) {
      // If any subsequent speed is greater than the previous, it's not decelerating to the end.
      if (smoothedSpeeds[lastComparisonPoint] < smoothedSpeeds[i]) {
        isDeceleratingToEnd = false
        break
      }
    }

    // If the lap ends with deceleration, consider the last data point as a trough.
    if (isDeceleratingToEnd) {
      troughs.push(smoothedSpeeds.length - 1)
    }

    this.breakingPoints = peaks
      .filter((peak, index) => {
        const nextTroughIndex = troughs[index]
        return (
          smoothedSpeeds[peak] - smoothedSpeeds[nextTroughIndex] >=
          speedThreshold
        )
      })
      .map((peakIndex) => this.latLng[peakIndex])

    if (isDeceleratingToEnd) {
      troughs = troughs.slice(0, troughs.length - 1)
    }

    troughs = filterTroughsByLowestSpeedWithinWindow(
      this.latLng,
      troughs,
      smoothedSpeeds
    )

    this.slowestPoints = troughs.map((troughIndex) => this.latLng[troughIndex])
  }

  public static fromDto(
    lapDto: LapDto,
    telemetry: Telemetry,
    trackId: string,
    trackSessionName: string,
    trackSessionId: string
  ): Lap {
    const lap = new Lap(
      lapDto.id,
      telemetry,
      new Date(lapDto.startTime),
      new Date(lapDto.finishTime),
      trackSessionName,
      trackSessionId,
      lapDto.lapNumber,
      lapDto.driverName ?? 'Unknown',
      trackId,
      telemetry?.latLngs?.[0]?.distanceTravelled ?? 0,
      telemetry?.latLngs?.at(-1)?.distanceTravelled ?? 0
    )
    return lap
  }
}
