import { useRef } from 'react'
import UploadFile from '@mui/icons-material/UploadFile'
import { Button } from '@mui/material'
import { useFileParser, SupportedFileTypes } from './useFileParser'

interface ParseProps {
  setProgressPercent: React.Dispatch<React.SetStateAction<number>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string | null | undefined>>
}

const Upload = ({ setProgressPercent, setIsLoading, setError }: ParseProps) => {
  // TODO: introduce multi-file select for concatenation of files. Each file type need its own implementation.
  const { parseFile } = useFileParser({
    setProgressPercent,
    setIsLoading,
    setError,
  })
  const inputFile = useRef<HTMLInputElement>(null)
  const handleFileUploadAsync = async (e: { target: HTMLInputElement }) => {
    const { files } = e.target
    if (files && files.length === 1) {
      await parseFile(files[0])
    }
  }

  const onButtonClick = () => {
    if (inputFile?.current != null) inputFile.current.click()
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        id="upload-file"
        style={{ display: 'none' }}
        accept={Object.values(SupportedFileTypes).join().toLowerCase()}
        ref={inputFile}
        onChange={handleFileUploadAsync}
        type="file"
        multiple
      />
      <Button
        variant="text"
        onClick={onButtonClick}
        sx={{
          color: 'white',
        }}
        startIcon={<UploadFile style={{ color: 'white' }} />}
      >
        Upload
      </Button>
    </div>
  )
}

export default Upload
