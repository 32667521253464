import { Buffer as BufferType } from 'buffer'

export function toBuffer(ab: ArrayBuffer): BufferType {
  const buf = BufferType.alloc(ab.byteLength)
  const view = new Uint8Array(ab)
  for (let i = 0; i < buf.length; ++i) {
    buf[i] = view[i]
  }
  return buf
}
