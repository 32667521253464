// FileParsingContext.tsx

import type React from 'react'
import {
  createContext,
  useState,
  useContext,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
  useMemo,
} from 'react'

interface FileParsingContextType {
  progressPercent: number
  setProgressPercent: Dispatch<SetStateAction<number>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  apiError: string | null | undefined
  setApiError: Dispatch<SetStateAction<string | null | undefined>>
}

const FileParsingContext = createContext<FileParsingContextType | undefined>(
  undefined
)

export const useFileParsing = () => {
  const context = useContext(FileParsingContext)
  if (context === undefined) {
    throw new Error('useFileParsing must be used within a FileParsingProvider')
  }
  return context
}

interface FileParsingProviderProps {
  children: ReactNode
}

export const FileParsingProvider: React.FC<FileParsingProviderProps> = ({
  children,
}) => {
  const [progressPercent, setProgressPercent] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<string | null | undefined>(null)

  const value = useMemo(
    () => ({
      progressPercent,
      setProgressPercent,
      isLoading,
      setIsLoading,
      apiError,
      setApiError,
    }),
    [
      progressPercent,
      setProgressPercent,
      isLoading,
      setIsLoading,
      apiError,
      setApiError,
    ]
  )

  return (
    <FileParsingContext.Provider value={value}>
      {children}
    </FileParsingContext.Provider>
  )
}
