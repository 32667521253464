import type React from 'react'
import { Drawer, Toolbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface GenericDrawerProps {
  drawerSide: 'left' | 'right'
  content: React.ReactNode
  isOpen: boolean
  toggleDrawer: () => void
}

export const GenericDrawer = ({
  drawerSide,
  content,
  isOpen,
  toggleDrawer: parentToggle,
}: GenericDrawerProps) => {
  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ['Tab', 'Shift'].includes((event as React.KeyboardEvent).key)
      ) {
        return
      }
      parentToggle()
    }

  // const contentWithDrawerControl = React.cloneElement(content as React.ReactElement<any>, { toggleDrawer });

  return (
    <Drawer anchor={drawerSide} open={isOpen} onClose={toggleDrawer()}>
      <Toolbar
        sx={{
          paddingBottom: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: drawerSide === 'right' ? 'flex-start' : 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer()}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      {content}
    </Drawer>
  )
}
