import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './core/App'
import reportWebVitals from './reportWebVitals'
import AuthInitializer from './auth/AuthInitializer'
import { Config } from './core/providers/ConfigurationContext'
import { ErrorProvider } from './core/providers/ErrorProvider'

const root = ReactDOM.createRoot(document.getElementById('root')!) // eslint-disable-line
root.render(
  <React.StrictMode>
    <ErrorProvider>
      <Config>
        <AuthInitializer>
          <App />
        </AuthInitializer>
      </Config>
    </ErrorProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
