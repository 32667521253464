export async function readFileAsText(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()

    // Define the callback function to handle the file contents
    reader.onload = (event) => {
      const fileContent = event.target?.result as string
      resolve(fileContent)
    }

    // Define the callback function to handle errors
    reader.onerror = (event) => {
      reject(event.target?.error)
    }

    // Read the file as text
    reader.readAsText(file)
  })
}

export async function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as ArrayBuffer)
    }

    reader.onerror = reject

    reader.readAsArrayBuffer(file)
  })
}

// export async function readFileAsBinaryString(file: File): Promise<string> {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     // Define the callback function to handle the file contents
//     reader.onload = (event) => {
//       const fileContent = event.target?.result as string;
//       resolve(fileContent);
//     };

//     // Define the callback function to handle errors
//     reader.onerror = (event) => {
//       reject(event.target?.error);
//     };

//     // Read the file as text
//     reader.readAsArrayBuffer(file);
//   });
// }
