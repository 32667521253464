import { LatLng } from 'leaflet'
import { round } from 'lodash'

// For linking chart to map: https://codesandbox.io/s/react-display-multiple-colored-gpx-tracks-on-a-leaflet-map-3rxo0?file=/src/components/map.js
// TODO: Capability to set speed color intervals.
// TODO: Change coloring from speed to timesplit, lean or g-force
// TODO: Download map of track to paint on
// TODO: Decouple from leaflet

export function roundToNearest5Meters(value: number): number {
  return Math.round(value / 5) * 5
  // Round to nearest 10, but adjust to end in 5 or 0
  const roundedTo10 = Math.round(value / 10) * 10
  const remainder = roundedTo10 % 10
  if (remainder === 0) {
    if (roundedTo10 - value > 5) {
      return roundedTo10 - 5
    }
    return roundedTo10
  } else {
    return roundedTo10 + (5 - remainder)
  }
}

export class ExtendedLatLong extends LatLng {
  date: Date
  speed: number
  timeString: string
  cts: number
  distanceTravelled: number
  constructor(
    latitude: number,
    longitude: number,
    date: Date,
    speed: number,
    distanceTravelled: number,
    timeString?: string,
    cts?: number
  ) {
    super(latitude, longitude, undefined)
    this.date = date
    this.speed = speed ? round(speed, 2) : speed
    this.timeString = timeString ?? ''
    this.cts = cts ?? 0
    this.distanceTravelled = roundToNearest5Meters(distanceTravelled)
  }
}
