import { useState, useCallback } from 'react'
import axios, { type AxiosError } from 'axios'
import { type CustomError, useApiUtils } from './useApiUtils'
import { type Configuration } from '../core/providers/ConfigurationContext'
import { type LapDto, type UpsertLapRequest } from '../apiTypes'
export interface LapsApiReturnType {
  upsertLap: (request: UpsertLapRequest) => Promise<void>
  getLap: (lapId: string, trackId: string) => Promise<LapDto | null>
  error: CustomError | null
}

export const useLapsApi = (
  bffConfig: Configuration | null
): LapsApiReturnType => {
  const [error, setError] = useState<CustomError | null>(null)
  const { getAuthToken, handleError, performApiCall } = useApiUtils(bffConfig)

  const resetErrors = () => {
    setError(null)
  }
  const upsertLap = useCallback(
    async (request: UpsertLapRequest): Promise<void> => {
      if (!bffConfig) return
      try {
        const token = await getAuthToken()
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/laps/${request.lap.id}`,
          request,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError) // Update the state with the error
      }
    },
    [bffConfig, getAuthToken]
  )

  const getLap = useCallback(
    async (lapId: string, trackId: string): Promise<LapDto | null> => {
      if (!bffConfig) return null
      try {
        const token = await getAuthToken()
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/laps/${lapId}/tracks/${trackId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  return {
    upsertLap: performApiCall(upsertLap, resetErrors),
    getLap: performApiCall(getLap, resetErrors),
    error,
  }
}
