import MP4Box, { type MP4ArrayBuffer, type MP4File, type MP4Info } from 'mp4box'
import ReadBlockFactory from './ReadBlockFactory'
import { toBuffer } from '../../../shared/utils/toBuffer'

interface ExtractOptions {
  progress?: (percent: number) => void
  cancellationToken?: { cancelled: boolean }
}

interface Timing {
  start?: Date
  videoDuration?: number
  frameDuration?: number
  samples?: Array<{ cts: number; duration: number }>
}

export async function extract(
  file: File,
  options: ExtractOptions = {}
): Promise<{ rawData: Buffer; timing: Timing }> {
  const mp4boxFile: MP4File = MP4Box.createFile(false)
  const timing: Timing = {}

  return await new Promise((resolve, reject) => {
    const readBlock = new ReadBlockFactory()
    // Providing false gives updates to 100% instead of just 50%, but seems to fail in Node
    // mp4boxFile = MP4Box.createFile(false)
    let uintArr: Uint8Array
    // Will store timing data to help analyse the extracted data
    // const timing: any = {}
    mp4boxFile.onError = reject

    // When the data is ready, look for the right track
    mp4boxFile.onReady = (videoData: MP4Info) => {
      let trackId: number | undefined
      let nbSamples: number | undefined
      let foundVideo: boolean = false
      // let foundVideo = false
      for (let i = 0; i < videoData.tracks.length; i++) {
        // Find the metadata track. Collect Id and number of samples
        if (videoData.tracks[i].codec === 'gpmd') {
          trackId = videoData.tracks[i].id
          nbSamples = videoData.tracks[i].nb_samples
          timing.start = videoData.tracks[i].created
          // Try to correct GoPro's badly encoded time zone
          timing.start.setMinutes(
            timing.start.getMinutes() + timing.start.getTimezoneOffset()
          )
        } else if (
          !foundVideo &&
          (videoData.tracks[i].type === 'video' ||
            videoData.tracks[i].name === 'VideoHandler' ||
            videoData.tracks[i].track_height > 0)
        ) {
          // Only confirm video track if found by type, in case more than one meet the other conditions
          if (videoData.tracks[i].type === 'video') foundVideo = true
          const vid = videoData.tracks[i]
          timing.videoDuration = vid.movie_duration / vid.movie_timescale
          // Deduce framerate from video track
          timing.frameDuration = timing.videoDuration / vid.nb_samples
        }
      }
      if (trackId != null) {
        // Request the track
        mp4boxFile.setExtractionOptions(trackId, null, {
          nbSamples: nbSamples ?? 0,
        })

        // When samples arrive
        mp4boxFile.onSamples = (
          id: number,
          user: unknown,
          samples: Array<{
            size: number
            cts: number
            duration: number
            data: Uint8Array
          }>
        ) => {
          readBlock.stop()
          const totalSamples = samples.reduce(function (acc, cur) {
            return acc + cur.size
          }, 0)

          // Save the time and duration of each sample
          timing.samples = []

          // Store them in Uint8Array
          uintArr = new Uint8Array(totalSamples)
          let runningCount = 0
          samples.forEach(function (sample) {
            if (timing.samples == null) {
              return
            }
            timing.samples.push({ cts: sample.cts, duration: sample.duration })
            // The loop prevents Firefox from crashing
            for (let i = 0; i < sample.size; i++) {
              uintArr.set(sample.data, runningCount)
            }
            runningCount += sample.size
          })

          // Convert to Buffer
          const rawData = toBuffer(uintArr)

          // And return it
          resolve({ rawData, timing })
        }
        mp4boxFile.start()
      } else {
        readBlock.stop()
        reject(new Error('Track not found'))
      }
    }

    // Use chunk system in browser
    // Define functions the child process will call
    const onParsedBuffer = (buffer: MP4ArrayBuffer, offset: number) => {
      if (buffer.byteLength === 0) {
        readBlock.stop()
        reject(new Error('File not compatible'))
      }

      buffer.fileStart = offset

      if (options.cancellationToken?.cancelled) {
        readBlock.stop()
        reject(new Error('Canceled by user'))
      } else {
        mp4boxFile.appendBuffer(buffer)
      }
    }
    // var flush = mp4boxFile.flush;
    // Try to use a web worker to avoid blocking the browser
    readBlock.read(file, {
      update: options.progress,
      onParsedBuffer,
      mp4boxFile,
      onError: reject,
    })
  })
}
