import moment from 'moment'

type InputTime = string | number // Can be a duration string or milliseconds number
type Granularity = 'seconds' | 'tenths' | 'hundredths' | 'milliseconds'

interface TimeSpanOptions {
  format?: string
  granularity?: Granularity
}

export const formatTimeSpan = (
  inputTime: InputTime,
  options?: TimeSpanOptions
): string | null => {
  if (inputTime === undefined || inputTime === null) return null

  let durationMs: number

  // Handle string durations and milliseconds numbers differently
  if (typeof inputTime === 'string') {
    // Input is a string duration
    durationMs = moment.duration(inputTime).asMilliseconds()
  } else {
    // Input is milliseconds
    durationMs = inputTime
  }

  const { granularity = 'milliseconds', format = 'mm:ss.SSS' } = options ?? {}
  let roundingFactor = 1
  let finalFormat = format

  switch (granularity) {
    case 'seconds':
      roundingFactor = 1000
      finalFormat = 'mm:ss'
      break
    case 'tenths':
      roundingFactor = 100
      finalFormat = 'mm:ss.S'
      break
    case 'hundredths':
      roundingFactor = 10
      finalFormat = 'mm:ss.SS'
      break
    // 'milliseconds' uses the default values
  }

  // Adjusting the time based on the selected granularity
  durationMs = Math.round(durationMs / roundingFactor) * roundingFactor

  return moment
    .utc(moment.duration(durationMs, 'milliseconds').asMilliseconds())
    .format(finalFormat)
}
