import type React from 'react'
import { createContext, useContext, useState, type ReactNode } from 'react'

interface VehicleDrawerContextType {
  isDrawerOpen: boolean
  toggleDrawer: () => void
}

const VehicleDrawerContext = createContext<
  VehicleDrawerContextType | undefined
>(undefined)

export const useVehicleDrawer = () => {
  const context = useContext(VehicleDrawerContext)
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }
  return context
}

interface Props {
  children: ReactNode
}

export const VehicleDrawerProvider: React.FC<Props> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <VehicleDrawerContext.Provider value={{ isDrawerOpen, toggleDrawer }}>
      {children}
    </VehicleDrawerContext.Provider>
  )
}
