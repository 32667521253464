import { type LatLng } from 'leaflet'
import { useEffect, useMemo, useRef, useState } from 'react'
import { type ExtendedLatLong } from '../../models/ExtendedLatLong'
import { type Lap } from '../../models/Lap'
import { type LapElapsedTimeDistance } from '../../models/LapElapsedTimeDistance'

export interface TimeDifference {
  time: string // Reference time from the fastest lap
  distance: number // Distance covered at this point in the fastest lap
  comparisons: Comparison[] // Array to store comparisons with slower laps
  fastestLapId: string
}

export interface Comparison {
  lapId: string // Identifier for the slower lap being compared
  timeDiff?: number // Time lost compared to the fastest lap at this point
  timeBasedLocation: LapElapsedTimeDistance
  distanceBasedLocation: LapElapsedTimeDistance // Details from the slower lap
}

function smoothTimeDifferences(
  timeDifferences: TimeDifference[],
  windowSize: number
): TimeDifference[] {
  const smoothedTimeDifferences = [...timeDifferences]

  const halfWindow = Math.floor(windowSize / 2)

  timeDifferences.forEach((td, i) => {
    const windowStart = Math.max(0, i - halfWindow)
    const windowEnd = Math.min(timeDifferences.length, i + halfWindow + 1)

    const windowTimeDifferences = smoothedTimeDifferences.slice(
      windowStart,
      windowEnd
    )

    td.comparisons.forEach((comparison, compIndex) => {
      if (!td.comparisons[compIndex].timeDiff) {
        return
      }
      const averageTimeLost = Math.round(
        windowTimeDifferences.reduce(
          (sum, current) =>
            sum + (current.comparisons[compIndex].timeDiff ?? 0),
          0
        ) / windowTimeDifferences.length
      )

      smoothedTimeDifferences[i].comparisons[compIndex].timeDiff =
        averageTimeLost
    })
  })

  return smoothedTimeDifferences
}

export function calculateDistance(point1: LatLng, point2: LatLng): number {
  const R = 6371e3 // Earth's radius in meters
  const lat1Rad = (point1.lat * Math.PI) / 180
  const lat2Rad = (point2.lat * Math.PI) / 180
  const deltaLat = ((point2.lat - point1.lat) * Math.PI) / 180
  const deltaLng = ((point2.lng - point1.lng) * Math.PI) / 180

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

function parseTimeString(timeString: string): number {
  const parts = timeString.split(':')
  const minutes = parseInt(parts[0], 10)
  const [seconds, tenths] = parts[1]
    .split('.')
    .map((part) => parseInt(part, 10))

  return (minutes * 60 + seconds) * 1000 + tenths * 100 // Convert to milliseconds
}

export function findClosestPoint(
  referencePoint: ExtendedLatLong,
  slowerLap: ExtendedLatLong[],
  lastClosestIndex: number,
  searchWindow: number = 50
): { closestPoint: ExtendedLatLong; closestIndex: number } {
  // Calculate start index to prevent backtracking
  const startIndex = Math.max(lastClosestIndex, 0)
  const endIndex = Math.min(slowerLap.length, startIndex + searchWindow)

  let closestPoint = slowerLap[startIndex]
  let smallestDistance = calculateDistance(referencePoint, closestPoint)
  let closestIndex = startIndex

  for (let i = startIndex + 1; i < endIndex; i++) {
    const currentDistance = calculateDistance(referencePoint, slowerLap[i])
    if (currentDistance < smallestDistance) {
      smallestDistance = currentDistance
      closestPoint = slowerLap[i]
      closestIndex = i
    }
  }

  return { closestPoint, closestIndex }
}

export const useTimeDrift = (laps: Lap[]) => {
  const [stateTimeDifference, setStateTimeDifference] = useState<
    TimeDifference[]
  >([])

  const [finalLineThatFixTimeSorting, setFinalLineThatFixTimeSorting] =
    useState<TimeDifference[]>([])
  // Find the closest point in the slower lap for a given point in the faster lap

  const timeDifferenceCache = useRef<Map<string, TimeDifference[]>>(
    new Map()
  ).current

  // Determine the fastest lap.
  const fastestLap = laps.length
    ? laps.reduce((fastest, current) => {
        const currentLatLng = current.getExtendedLatLongs()
        const fastestLatLng = fastest.getExtendedLatLongs()
        return parseTimeString(
          currentLatLng[currentLatLng.length - 1].timeString
        ) < parseTimeString(fastestLatLng[fastestLatLng.length - 1].timeString)
          ? current
          : fastest
      })
    : undefined

  function calculateTimeDifferences(laps: Lap[]): TimeDifference[] {
    if (laps.length < 2 || !fastestLap) return []

    const cacheKey = laps
      .map((lap) => lap.id)
      .sort()
      .join('-')
    // Check if the result is already cached
    if (timeDifferenceCache.has(cacheKey)) {
      return timeDifferenceCache.get(cacheKey) ?? []
    }

    const timeDifferences: TimeDifference[] = fastestLap
      .getExtendedLatLongs()
      .map((point) => ({
        time: point.timeString,
        distance: point.distanceTravelled - fastestLap.lapStartDistance,
        fastestLapId: fastestLap.id,
        comparisons: [],
      }))

    laps.forEach((lap) => {
      let lastClosestIndex = 0
      fastestLap.lapElapsedTimeDistances.forEach((fastestLapPoint, index) => {
        if (lap.id === fastestLap.id) {
          timeDifferences[index].comparisons.push({
            lapId: lap.id,
            timeBasedLocation: fastestLapPoint,
            distanceBasedLocation: fastestLapPoint,
          })
          return
        }

        const { closestIndex } = findClosestPoint(
          fastestLapPoint.latLng,
          lap.getExtendedLatLongs(),
          lastClosestIndex
        )
        lastClosestIndex = closestIndex
        const closestPoint = lap.lapElapsedTimeDistances[closestIndex]
        const timeLost = closestPoint.elapsedMs - fastestLapPoint.elapsedMs

        timeDifferences[index].comparisons.push({
          lapId: lap.id,
          timeDiff: timeLost,
          timeBasedLocation: {
            ...closestPoint,
            latLng:
              lap.findLatLngForTimeString(fastestLapPoint.timeString) ??
              lap.lapElapsedTimeDistances[closestIndex].latLng,
          },
          distanceBasedLocation: closestPoint,
        })
      })
    })
    const windowSize = 15
    const smoothedTimeDifferences = smoothTimeDifferences(
      timeDifferences,
      windowSize
    )
    timeDifferenceCache.set(cacheKey, smoothedTimeDifferences)

    return smoothedTimeDifferences
  }

  const timeDifferences = useMemo(
    () => calculateTimeDifferences(laps),
    [laps.length]
  )

  useEffect(() => {
    setStateTimeDifference(timeDifferences)

    if (!timeDifferences.length) return

    const lineThatFixTimeSorting: TimeDifference[] = timeDifferences
      .flatMap((series) => series.time)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map((time) => {
        return { time, distance: 0, comparisons: [], fastestLapId: '' }
      })

    setFinalLineThatFixTimeSorting(lineThatFixTimeSorting)
  }, [timeDifferences])

  return {
    timeDifference: stateTimeDifference,
    lineThatFixSorting: finalLineThatFixTimeSorting,
    fastestLap,
  }
}
