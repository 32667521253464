import { useCallback } from 'react'
import axios from 'axios'
import { type Configuration } from '../core/providers/ConfigurationContext'

export interface ConfigApiReturnType {
  getConfiguration: () => Promise<Configuration | null>
  getClientId: () => Promise<string>
}

export const useConfigApi = (): ConfigApiReturnType => {
  const getConfiguration =
    useCallback(async (): Promise<Configuration | null> => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/config`
        )
        return response.data
      } catch (error) {
        console.error('An error occurred while fetching config: ', error)
        return null
      }
    }, [])

  const getClientId = useCallback(async (): Promise<string> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/config/client-id`
      )
      return response.data
    } catch (error) {
      console.error('An error occurred while fetching config: ', error)
      return ''
    }
  }, [])

  return { getConfiguration, getClientId }
}
