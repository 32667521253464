import React, { useContext, useEffect, useState } from 'react'
import useApi from '../../api/useApi'
import CenterSpinner from '../../components/CenterSpinner'

export interface Configuration {
  clientId: string
  apiScope: string
  environment: string
  applicationInsightsConnectionString: string
}

const ConfigurationContext = React.createContext<Configuration | null>(null)

export const ConfigurationProvider = ConfigurationContext.Provider
export const ConfigurationConsumer = ConfigurationContext.Consumer
export default ConfigurationContext

interface Props {
  children: React.ReactNode
}

export const useConfiguration = (): Configuration => {
  const context = useContext(ConfigurationContext)
  if (context == null) {
    throw new Error(
      'useConfiguration must be used within a ConfigurationProvider'
    )
  }
  return context
}

export const Config: React.FC<Props> = ({ children }) => {
  const { configApi } = useApi(null)
  const [configuration, setConfiguration] = useState<Configuration | null>(null)

  useEffect(() => {
    const initializeConfig = async (): Promise<void> => {
      const bffConfiguration = await configApi.getConfiguration()
      if (bffConfiguration == null) {
        return
      }
      setConfiguration(bffConfiguration)
    }

    void initializeConfig()
  }, [])

  if (configuration == null) {
    return <CenterSpinner width="100%" />
  }

  return (
    <ConfigurationProvider value={configuration}>
      {children}
    </ConfigurationProvider>
  )
}
