import { useCallback, useState } from 'react'
import axios, { type AxiosError } from 'axios'
import { type Configuration } from '../core/providers/ConfigurationContext'
import { type CustomError, useApiUtils } from './useApiUtils'
import { type FeatureFlags } from '../core/providers/FeatureFlagContext'

export interface FeatureFlagsApiReturnType {
  getFeatureFlags: () => Promise<FeatureFlags | null>
  error: CustomError | null
}

export const useFeatureFlagsApi = (
  bffConfig: Configuration | null
): FeatureFlagsApiReturnType => {
  const [error, setError] = useState<CustomError | null>(null)
  const { getAuthToken, handleError, performApiCall } = useApiUtils(bffConfig)
  const resetErrors = () => {
    setError(null)
  }
  const getFeatureFlags =
    useCallback(async (): Promise<FeatureFlags | null> => {
      if (!bffConfig) return null
      try {
        const token = await getAuthToken()
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/feature-flags`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data as FeatureFlags
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    }, [bffConfig, getAuthToken])

  return {
    getFeatureFlags: performApiCall(getFeatureFlags, resetErrors),
    error,
  }
}
