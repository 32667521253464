import type React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

const ShowcaseComponent: React.FC = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '40vh', sm: '50vh', md: '60vh', xl: '70vh' },
        backgroundImage: 'url(./background.webp)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        p: { xs: 1, sm: 2 },
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          color: 'common.white',
          textShadow: '1px 1px 8px rgba(0, 0, 0, 0.7)',
          marginBottom: 2,
          fontSize: {
            xs: '1.25rem', // Smaller for extra-small devices
            sm: '2rem', // Adjusted for small devices
            md: '2.5rem', // Medium devices
            lg: '3rem', // Large devices
            xl: '3.5rem', // Extra-large devices
          },
        }}
      >
        Speed Simplified: Analyze, Compare, Excel
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: 2, sm: 4, md: 8 },
          width: '80%',
          maxWidth: '1200px',
          mx: 'auto',
        }}
      >
        {/* Mobile Image */}
        <Box
          component="img"
          sx={{
            width: '15%',
            maxWidth: '300px',
            minWidth: '75px',
            height: 'auto',
          }}
          src="./mobile-portrait.webp"
          alt="App on iPhone"
        />

        {/* Desktop Image with Border */}
        <Box
          component="img"
          sx={{
            width: '50%',
            maxWidth: '1200px',
            height: 'auto',
            minWidth: '200px',
            border: {
              xs: `4px solid ${theme.palette.common.black}`,
              sm: `10px solid ${theme.palette.common.black}`,
            }, // Adjustable border width
            borderRadius: '8px',
          }}
          src="./desktop.webp"
          alt="App on Desktop"
        />
      </Box>
    </Box>
  )
}

export default ShowcaseComponent
