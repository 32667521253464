import type React from 'react'
import { createContext, useContext, useState, type ReactNode } from 'react'

interface LoadDrawerContextType {
  isDrawerOpen: boolean
  toggleDrawer: () => void
}

const LoadDrawerContext = createContext<LoadDrawerContextType | undefined>(
  undefined
)

export const useLoadDrawer = () => {
  const context = useContext(LoadDrawerContext)
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }
  return context
}

interface Props {
  children: ReactNode
}

export const LoadDrawerProvider: React.FC<Props> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <LoadDrawerContext.Provider value={{ isDrawerOpen, toggleDrawer }}>
      {children}
    </LoadDrawerContext.Provider>
  )
}
