import { type AcclReading, type GyroReading2 } from '../../../api/Telemetry.dto'
import { type PzRacingDataRow } from '../../../apiTypes'

export const simpleMovingAverage = (
  reading: number[],
  window = 5,
  n = Infinity
) => {
  if (reading.length < window) {
    return []
  }
  let index = window - 1
  const length = reading.length + 1
  const simpleMovingAverages: number[] = []
  let numberOfSMAsCalculated = 0
  while (++index < length && numberOfSMAsCalculated++ < n) {
    const windowSlice = reading.slice(index - window, index)
    const sum = windowSlice.reduce((prev, curr) => prev + curr, 0)
    simpleMovingAverages.push(sum / window)
  }
  return simpleMovingAverages
}

export const AverageAccelerometerEveryTenthSecond = (
  samples: AcclReading[]
): AcclReading[] => {
  const groupedSamples: Record<number, AcclReading[]> = {}

  samples.forEach((sample) => {
    const roundedCts = Math.round(sample.cts / 100) * 100
    if (!groupedSamples[roundedCts]) { // eslint-disable-line
      groupedSamples[roundedCts] = []
    }
    groupedSamples[roundedCts].push(sample)
  })

  const averagedSamples: AcclReading[] = []

  for (const cts in groupedSamples) {
    const group = groupedSamples[cts]
    const avgX = group.reduce((sum, sample) => sum + sample.x, 0) / group.length
    const avgY = group.reduce((sum, sample) => sum + sample.y, 0) / group.length
    const avgZ = group.reduce((sum, sample) => sum + sample.z, 0) / group.length
    const representativeSample = group[0]

    averagedSamples.push({
      cts: parseInt(cts),
      date: representativeSample.date,
      x: avgX,
      y: avgY,
      z: avgZ,
      unit: representativeSample.unit,
    })
  }

  return averagedSamples
}

export const AverageGyroEveryTenthSecond = (
  samples: GyroReading2[]
): GyroReading2[] => {
  const groupedSamples: Record<number, GyroReading2[]> = {}

  samples.forEach((sample) => {
    const roundedCts = Math.round(sample.cts / 100) * 100
    if (!groupedSamples[roundedCts]) { // eslint-disable-line
      groupedSamples[roundedCts] = []
    }
    groupedSamples[roundedCts].push(sample)
  })

  const averagedSamples: GyroReading2[] = []

  for (const cts in groupedSamples) {
    const group = groupedSamples[cts]
    const avgX = group.reduce((sum, sample) => sum + sample.x, 0) / group.length
    const avgY = group.reduce((sum, sample) => sum + sample.y, 0) / group.length
    const avgZ = group.reduce((sum, sample) => sum + sample.z, 0) / group.length
    const representativeSample = group[0]

    averagedSamples.push({
      cts: parseInt(cts),
      date: representativeSample.date,
      x: avgX,
      y: avgY,
      z: avgZ,
      unit: representativeSample.unit,
    })
  }

  return averagedSamples
}

export function aggregateData(data: PzRacingDataRow[]): PzRacingDataRow[] {
  let aggregateTime = 0
  let aggregateLongitude = 0
  let aggregateLatitude = 0
  let aggregateDirection = 0
  let aggregateSpeed = 0
  let count = 0
  const output: PzRacingDataRow[] = []
  const startDate = data[0].date

  data.forEach((item, index) => {
    const roundedTime = Math.round(item.time * 10) / 10
    const roundedDate = truncateDateAndAddMilliseconds(startDate, roundedTime)
    // If we're still within the same 1/10th of a second, aggregate the data
    if (roundedTime === aggregateTime || index === 0) {
      aggregateTime = roundedTime
      aggregateLongitude += item.longitude
      aggregateLatitude += item.latitude
      aggregateDirection += item.direction
      aggregateSpeed += item.speed
      count++
    } else {
      // Otherwise, calculate the averages and add the aggregated data to the output
      output.push({
        time: aggregateTime,
        longitude: aggregateLongitude / count,
        latitude: aggregateLatitude / count,
        direction: aggregateDirection / count,
        speed: aggregateSpeed / count,
        date: roundedDate,
      })

      // Then reset the aggregates for the next 1/10th of a second
      aggregateTime = roundedTime
      aggregateLongitude = item.longitude
      aggregateLatitude = item.latitude
      aggregateDirection = item.direction
      aggregateSpeed = item.speed
      count = 1
    }

    // Handle the last group of data
    if (index === data.length - 1) {
      output.push({
        time: aggregateTime,
        longitude: aggregateLongitude / count,
        latitude: aggregateLatitude / count,
        direction: aggregateDirection / count,
        speed: aggregateSpeed / count,
        date: roundedDate,
      })
    }
  })

  return output
}

function truncateDateAndAddMilliseconds(
  originalDate: Date,
  millisecondsToAdd: number
) {
  // Clone the original date

  let truncatedDate = new Date(new Date(originalDate).getTime())

  // Truncate to the nearest second (set milliseconds to 0)
  truncatedDate.setMilliseconds(0)

  // Add the specified milliseconds
  const time = truncatedDate.getTime() + millisecondsToAdd * 1000
  truncatedDate = new Date(time)

  return truncatedDate
}

export function aggregateDataMedian(
  data: PzRacingDataRow[]
): PzRacingDataRow[] {
  const aggregatedData = new Map<number, PzRacingDataRow[]>()
  const output: PzRacingDataRow[] = []
  const startDate = data[0].date

  // Helper function to calculate the median
  const median = (values: number[]): number => {
    values.sort((a, b) => a - b)
    const mid = Math.floor(values.length / 2)
    return values.length % 2 !== 0
      ? values[mid]
      : (values[mid - 1] + values[mid]) / 2
  }

  data.forEach((item) => {
    const roundedTime = Math.round(item.time * 10) / 10
    const roundedDate = truncateDateAndAddMilliseconds(startDate, roundedTime)

    const aggregatedValue = aggregatedData.get(roundedTime) ?? []

    aggregatedValue.push({
      time: roundedTime,
      longitude: item.longitude,
      latitude: item.latitude,
      direction: item.direction,
      speed: item.speed,
      date: roundedDate,
    })

    aggregatedData.set(roundedTime, aggregatedValue)
  })

  aggregatedData.forEach((values, time) => {
    const roundedDate = truncateDateAndAddMilliseconds(values[0].date, time)
    output.push({
      time,
      longitude: median(values.map((item) => item.longitude)),
      latitude: median(values.map((item) => item.latitude)),
      direction: median(values.map((item) => item.direction)),
      speed: median(values.map((item) => item.speed)),
      date: roundedDate,
    })
  })

  return output.sort((a, b) => a.time - b.time)
}
