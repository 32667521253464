import type React from 'react'
import ConfigurationContext from '../../core/providers/ConfigurationContext'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import { useContext, useState } from 'react'
import useApi from '../../api/useApi'
import CenterSpinner from '../../components/CenterSpinner'

interface VehicleFormData {
  type: string
  name: string
  brand: string
  model: string
  version?: string
  manufactureYear?: number
  power?: number
  torque?: number
  engineCapacity?: number
  weight?: number
  isPrimary: boolean
}

export interface VehicleFormProps {
  onCancel: () => void
  onSuccess?: () => void
}

const VehicleForm: React.FC<VehicleFormProps> = (props) => {
  const { control, handleSubmit } = useForm<VehicleFormData>()
  const { onCancel, onSuccess } = props
  const bffConfig = useContext(ConfigurationContext)
  const { vehicleApi } = useApi(bffConfig)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (data: VehicleFormData) => {
    setIsLoading(true)
    await vehicleApi.createVehicle({
      brand: data.brand,
      isPrimary: data.isPrimary,
      name: data.name,
      type: data.type,
      manufactureYear: data.manufactureYear,
      model: data.model,
      weightKg: data.weight,
      engine: {
        capacityCc: data.engineCapacity,
        powerKw: data.power,
        torqueNm: data.torque,
      },
    })
    setIsLoading(false)
    if (onSuccess) onSuccess()
  }

  if (isLoading) return <CenterSpinner />

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        ADD VEHICLE
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              label="name"
              required
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              exclusive
              {...field}
              onChange={(_, value) => {
                field.onChange(value)
              }}
            >
              <ToggleButton value="MOTORBIKE">Motorbike</ToggleButton>
              <ToggleButton value="CAR">Car</ToggleButton>
              <ToggleButton value="KART">Kart</ToggleButton>
              <ToggleButton value="OTHER">Other</ToggleButton>
            </ToggleButtonGroup>
          )}
        />

        <Controller
          name="brand"
          control={control}
          render={({ field }) => (
            <TextField
              label="Brand"
              required
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />

        <Controller
          name="model"
          control={control}
          render={({ field }) => (
            <TextField
              label="Model"
              required
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />

        {/* Repeat the pattern for other fields */}

        <Controller
          name="isPrimary"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="This is my primary vehicle"
            />
          )}
        />

        <Button type="submit" color="primary" variant="contained">
          Save
        </Button>
        <Button
          type="button"
          color="secondary"
          variant="text"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </form>
    </div>
  )
}

export default VehicleForm
