import { type ExtendedLatLong } from '../../models/ExtendedLatLong'

export const calculateMovingAverageSpeed = (
  latLngs: ExtendedLatLong[],
  windowSize: number
): number[] => {
  const smoothedSpeeds = new Array<number>(latLngs.length).fill(0)
  const halfWindowSize = Math.floor(windowSize / 2)

  latLngs.forEach((_, i) => {
    // Adjust the start and end of the slice to include points before and after the current index
    // Ensure we don't go out of bounds by using Math.max and Math.min
    const start = Math.max(0, i - halfWindowSize)
    const end = Math.min(latLngs.length, i + halfWindowSize + 1) // Add 1 because slice does not include the end index

    const speedsToAverage = latLngs.slice(start, end)

    smoothedSpeeds[i] =
      speedsToAverage.reduce((acc, cur) => acc + cur.speed, 0) /
      speedsToAverage.length
  })

  return smoothedSpeeds
}

export const findPeaks = (smoothedSpeeds: number[], range: number = 2) => {
  // Implementation remains the same
  const peaks: number[] = []
  // Start from the first possible point given the range and iterate to the possible last point
  for (let i = range; i < smoothedSpeeds.length - range; i++) {
    let isPeak = true
    // Check points before and after the current point within the specified range
    for (let j = 1; j <= range; j++) {
      if (
        smoothedSpeeds[i] <= smoothedSpeeds[i - j] ||
        smoothedSpeeds[i] <= smoothedSpeeds[i + j]
      ) {
        isPeak = false
        break
      }
    }
    if (isPeak) {
      peaks.push(i)
    }
  }

  // Special handling for the lap ending in acceleration:
  // Consider the last point a peak if the speed consistently increases to the end.
  let isEndAcceleration = true
  for (
    let i = smoothedSpeeds.length - range - 1;
    i < smoothedSpeeds.length - 1;
    i++
  ) {
    if (smoothedSpeeds[i] >= smoothedSpeeds[i + 1]) {
      isEndAcceleration = false
      break
    }
  }
  if (isEndAcceleration) {
    peaks.push(smoothedSpeeds.length - 1)
  }

  return peaks
}

export const identifyTroughsBetweenPeaks = (
  peaks: number[],
  smoothedSpeeds: number[]
) => {
  const troughs: number[] = []
  for (let i = 0; i < peaks.length - 1; i++) {
    let minIndex = peaks[i]
    let minValue = smoothedSpeeds[peaks[i]]
    for (let j = peaks[i]; j <= peaks[i + 1]; j++) {
      if (smoothedSpeeds[j] < minValue) {
        minValue = smoothedSpeeds[j]
        minIndex = j
      }
    }
    troughs.push(minIndex)
  }

  return troughs
}

export const filterTroughsByLowestSpeedWithinWindow = (
  latLngs: ExtendedLatLong[],
  troughs: number[],
  smoothedSpeeds: number[]
): number[] => {
  // Assuming each entry in `this.latLng` has a `date` and `speed`, and `troughs` contains indexes of these entries
  const filteredTroughs: number[] = []

  troughs.forEach((troughIndex, _, array) => {
    const currentTime = latLngs[troughIndex].date.getTime()

    // Find troughs within a 1-second window before and after the current trough
    const relevantTroughs = array.filter((index) => {
      const timeDifference = Math.abs(
        latLngs[index].date.getTime() - currentTime
      )
      return timeDifference <= 2000 // ms
    })

    // Find the index of the lowest speed among these relevant troughs
    if (relevantTroughs.length > 0) {
      const lowestSpeedIndex = relevantTroughs.reduce(
        (lowest, current) =>
          smoothedSpeeds[current] < smoothedSpeeds[lowest] ? current : lowest,
        relevantTroughs[0]
      )

      // Ensure we're not adding duplicate troughs
      if (!filteredTroughs.includes(lowestSpeedIndex)) {
        filteredTroughs.push(lowestSpeedIndex)
      }
    }
  })

  // Return the filtered list of troughs
  return filteredTroughs
}
