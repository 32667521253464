import { type MP4ArrayBuffer } from 'mp4box'

interface ReadCallbacks {
  update?: (progress: number) => void
  onParsedBuffer: (arrayBuffer: MP4ArrayBuffer, offset: number) => void
  mp4boxFile: {
    flush: () => void
  }
  onError: (message: string) => void
}

class ReadBlockFactory {
  // Read chunks progressively in browser
  private readonly chunkSize = 1024 * 1024 * 2 // bytes
  private offsetFlag = 0
  private offset = 0
  private gotSamples = false

  stop = (): void => {
    this.gotSamples = true
  }

  read = (
    file: File,
    { update, onParsedBuffer, mp4boxFile, onError }: ReadCallbacks
  ): void => {
    const fileSize = file.size
    const r = new FileReader()
    const blob = file.slice(this.offset, this.chunkSize + this.offset)

    r.onload = (evt: ProgressEvent<FileReader>) => {
      const result = evt.target?.result
      if (result instanceof ArrayBuffer) {
        const mp4ArrayBuffer: MP4ArrayBuffer = Object.assign(result, {
          fileStart: this.offset,
        })

        // Tell parent function to add data to mp4box
        onParsedBuffer(mp4ArrayBuffer, this.offset)
        // Record offset for next chunk
        this.offset += result.byteLength
        // Provide proress percentage to parent function
        const prog =
          Math.ceil((50 * this.offset) / fileSize) + 50 * this.offsetFlag
        if (prog > 200) onError('Progress went beyond 100%')
        else if (update) update(prog)
      } else
        onError('Read error: ' + evt.target?.error?.message ?? 'Unknown error')

      // Adapt offset to larger file sizes
      if (this.offset >= fileSize) {
        // Tell parent function to flush mp4box
        mp4boxFile.flush()
        this.offset = 0
        this.offsetFlag++
        if (!this.gotSamples) {
          this.read(file, { update, onParsedBuffer, mp4boxFile, onError })
        }
        return
      }
      if (!this.gotSamples) {
        this.read(file, { update, onParsedBuffer, mp4boxFile, onError })
      }
    }
    r.onerror = (): void => {
      onError('FileReader error: ' + r.error?.toString())
    }

    r.readAsArrayBuffer(blob)
  }
}

export default ReadBlockFactory
