import { useState, useCallback } from 'react'
import axios, { type AxiosError } from 'axios'
import { type CustomError, useApiUtils } from './useApiUtils'
import { type Configuration } from '../core/providers/ConfigurationContext'
import { type CreateVehicleRequest, type VehicleDto } from '../apiTypes'

export interface VehicleApiReturnType {
  createVehicle: (vechicle: CreateVehicleRequest) => Promise<VehicleDto | null>
  getVehicles: () => Promise<VehicleDto[]>
  getVehicle: (vehicleId: string) => Promise<VehicleDto | null>
  deleteVehicle: (vehicleId: string) => Promise<void>
  updateVehicle: (vehicle: VehicleDto) => Promise<VehicleDto | null>
  error: CustomError | null
}

export const useVehicleApi = (
  bffConfig: Configuration | null
): VehicleApiReturnType => {
  const [error, setError] = useState<CustomError | null>(null)
  const { getAuthToken, handleError, performApiCall } = useApiUtils(bffConfig)
  const resetErrors = () => {
    setError(null)
  }
  const getVehicle = useCallback(
    async (vehicleId: string): Promise<VehicleDto | null> => {
      try {
        const token = await getAuthToken()
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/vehicle/${vehicleId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data as VehicleDto
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  const deleteVehicle = useCallback(
    async (vehicleId: string): Promise<void> => {
      try {
        const token = await getAuthToken()
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/vehicle/${vehicleId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
      }
    },
    [bffConfig, getAuthToken]
  )

  const updateVehicle = useCallback(
    async (vehicle: VehicleDto): Promise<VehicleDto | null> => {
      try {
        const token = await getAuthToken()
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/vehicle/${vehicle.id}`,
          vehicle,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data as VehicleDto
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  const createVehicle = useCallback(
    async (vehicle: CreateVehicleRequest): Promise<VehicleDto | null> => {
      try {
        const token = await getAuthToken()
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/vehicle`,
          vehicle,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        return response.data as VehicleDto
      } catch (error) {
        const handledError = handleError(error as AxiosError)
        setError(handledError)
        return null
      }
    },
    [bffConfig, getAuthToken]
  )

  const getVehicles = useCallback(async (): Promise<VehicleDto[]> => {
    try {
      const token = await getAuthToken()
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/vehicle`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      return response.data as VehicleDto[]
    } catch (error) {
      const handledError = handleError(error as AxiosError)
      setError(handledError)
      return []
    }
  }, [bffConfig, getAuthToken])

  return {
    createVehicle: performApiCall(createVehicle, resetErrors),
    getVehicles: performApiCall(getVehicles, resetErrors),
    getVehicle: performApiCall(getVehicle, resetErrors),
    deleteVehicle: performApiCall(deleteVehicle, resetErrors),
    updateVehicle: performApiCall(updateVehicle, resetErrors),
    error,
  }
}
