import { extract } from '../extracts/gpmf-extract'
import { readFileAsText } from './readFileAsText'
import { toBuffer } from '../../../shared/utils/toBuffer'

export async function readFile(
  file: File,
  { progress, cancellationToken }: any // eslint-disable-line
) {
  if (file.name.endsWith('.json')) {
    const fileContent = await readFileAsText(file)
    const { rawData, timing } = JSON.parse(fileContent)
    const uintArr = new Uint8Array(rawData.data.length) // eslint-disable-line
    for (let i = 0; i < uintArr.length; i++) {
      uintArr[i] = rawData.data[i]
    }
    timing.start = new Date(timing.start) // eslint-disable-line
    return { rawData: toBuffer(uintArr), timing }
  } else {
    const result = await extract(file, { progress, cancellationToken })
    // TODO: Log error
    if (result == null) return
    return { rawData: result.rawData, timing: result.timing }
  }
}
