import { ExtendedLatLong } from '../../models/ExtendedLatLong'
import useApi from '../../api/useApi'
import ConfigurationContext from '../../core/providers/ConfigurationContext'
import { useContext } from 'react'
import { VideoSession } from '../video/VideoSession'
import { readFile } from './file/readFile'
import { useBaseProcessor } from './useBaseProcessor'
import { LatLng } from 'leaflet'
import { useTrackSessionContext } from '../trackSession/TrackSessionContext'
import { TelemetryType } from '../../apiTypes'
import telementry from './telemetry/telemetry'
import {
  type Telemetry,
  type GyroSample,
  type GyroReading2,
  type AcclSample,
  type AcclReading,
  type Root,
} from '../../api/Telemetry.dto'
import { calculateDistance } from '../timeDrift/useTimeDrift'

export const useGoProProcessor = (
  setProgressPercent: React.Dispatch<React.SetStateAction<number>>
) => {
  const bffConfig = useContext(ConfigurationContext)
  const api = useApi(bffConfig)
  const { setVideoSessions } = useTrackSessionContext()
  const { loadTrack, getFirstCoordinate, updateTrackSessions } =
    useBaseProcessor()
  const { error: apiError } = api
  const parseTelemetry = async (
    results: Array<{ rawData: any; timing: any }>  // eslint-disable-line
  ): Promise<Telemetry> => {
    const gps5: Root = await telementry(results, {
      stream: ['GPS5'],
      repeatSticky: true,
      repeatHeaders: true,
      GPS5Fix: 2,
      GPS5Precision: 500,
    })
    const gyro: Root = await telementry(results, {
      stream: 'GYRO',
      repeatSticky: true,
      repeatHeaders: true,
    })
    const accl: Root = await telementry(results, {
      stream: 'ACCL',
      repeatSticky: true,
      repeatHeaders: true,
    })

    let totalDistance = 0
    const latLngs = gps5[1].streams.GPS5?.samples.reduce<ExtendedLatLong[]>(
      (accumulatedLatLngs, currentSample, index, allSamples) => {
        // If we are not at the first sample, calculate the distance from the previous sample
        if (index > 0) {
          const previousSample = allSamples[index - 1]
          // Ensure that the latitude and longitude of both previous and current samples are not null
          if (
            previousSample['GPS (Lat.) [deg]'] != null &&
            previousSample['GPS (Long.) [deg]'] != null &&
            currentSample['GPS (Lat.) [deg]'] != null &&
            currentSample['GPS (Long.) [deg]'] != null
          ) {
            const distance = calculateDistance(
              new LatLng(
                previousSample['GPS (Lat.) [deg]'],
                previousSample['GPS (Long.) [deg]']
              ),
              new LatLng(
                currentSample['GPS (Lat.) [deg]'],
                currentSample['GPS (Long.) [deg]']
              )
            )
            totalDistance += distance
          }
        }

        // Ensure the current sample has valid latitude and longitude before creating an ExtendedLatLong object
        if (
          currentSample['GPS (Lat.) [deg]'] != null &&
          currentSample['GPS (Long.) [deg]'] != null
        ) {
          // Convert speed from m/s to km/h
          const speed = currentSample['GPS (2D speed) [m/s]'] * 3.6

          // Create a new ExtendedLatLong object with the current sample's data
          const latLng = new ExtendedLatLong(
            currentSample['GPS (Lat.) [deg]'],
            currentSample['GPS (Long.) [deg]'],
            new Date(currentSample.date),
            speed,
            totalDistance,
            undefined,
            currentSample.cts
          )

          // Add the new ExtendedLatLong object to the accumulated array
          accumulatedLatLngs.push(latLng)
        }

        return accumulatedLatLngs
      },
      [] // Initial value for the accumulated array
    )

    const gyroReading = gyro[1].streams.GYRO?.samples.map((g: GyroSample) => {
      const reading: GyroReading2 = {
        cts: g.cts,
        date: new Date(g.date),
        x: g['Gyroscope (x) [rad/s]'],
        y: g['Gyroscope (y) [rad/s]'],
        z: g['Gyroscope (z) [rad/s]'],
        unit: 'rad/s',
      }
      return reading
    })

    const acclReading = accl[1].streams.ACCL?.samples.map((g: AcclSample) => {
      const reading: AcclReading = {
        cts: g.cts,
        date: new Date(g.date),
        x: g['Accelerometer (x) [m/s2]'],
        y: g['Accelerometer (y) [m/s2]'],
        z: g['Accelerometer (z) [m/s2]'],
        unit: 'm/s2',
      }
      return reading
    })

    return {
      latLngs,
      gyro: gyroReading,
      accl: acclReading,
      device: { name: 'GoPro', version: '8' },
    }
  }

  const processFile = async (file: File, sessionId: string): Promise<void> => {
    // GoPro (LRV) file processing
    const progress = (percent: number) => {
      setProgressPercent(percent)
    }

    const storedTrackSession = await api.trackSessionApi.getTrackSessionByName(
      file.name,
      [TelemetryType.Gps]
    )
    if (storedTrackSession) {
      const sessionFeatures = { hasVideo: true, hasLeanAngle: true }
      await loadTrack(storedTrackSession, sessionFeatures)
      setVideoSessions((prevState) => [
        ...prevState,
        new VideoSession(storedTrackSession.id, file),
      ])
      return
    }

    const cancellationToken = { cancelled: false }
    const results = []
    const result = await readFile(file, { progress, cancellationToken })
    if (result == null) return

    results.push(result)
    const telemetry = await parseTelemetry(results)
    const firstCoordinate = getFirstCoordinate(telemetry)
    if (firstCoordinate == null) return
    const track = await api.trackApi.findTrack(firstCoordinate)
    updateTrackSessions(
      telemetry,
      sessionId,
      track,
      { hasLeanAngle: true, hasVideo: true },
      file.name
    )
    setVideoSessions((prevState) => [
      ...prevState,
      new VideoSession(sessionId, file),
    ])
  }

  return { processFile, error: apiError }
}
