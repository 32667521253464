import {
  ApplicationInsights,
  type ITelemetryItem,
} from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
// import { createBrowserHistory } from "history";
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
// const browserHistory = createBrowserHistory({ basename: '' });

// Adjustments to Application Insights setup
export const initializeAppInsights = (connectionString: string) => {
  const clickPluginInstance = new ClickAnalyticsPlugin()

  const clickPluginConfig = {
    autoCapture: true,
    dataTags: {
      useDefaultContentNameOrId: true,
    },
  }

  const reactPlugin = new ReactPlugin()
  const appInsights = new ApplicationInsights({
    config: {
      connectionString:
        connectionString ??
        'InstrumentationKey=00000000-0000-0000-0000-000000000000;EndpointSuffix=ai.contoso.com;',
      extensions: [reactPlugin, clickPluginInstance],
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      extensionConfig: {
        // [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
    },
  })

  appInsights.loadAppInsights()
  appInsights.trackPageView()

  appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags ?? []
    env.tags['ai.cloud.role'] = 'PitBrain'
  })
  return { reactPlugin, appInsights }
}
